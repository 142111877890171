import React from "react";
import DropdownItem from "./DropdownItem";
import ActionButton from "../buttons/ActionButton";
import { useTranslation } from "react-i18next";
function Dropdown({
  children,
  toggle,
  onToggle,
  submit,
  onSubmit,
  title,
  ...attributes
}) {
  const { t } = useTranslation("global");
  return (
    <>
      <div className="rounded-lg shadow py-2 w-64 max-w-88 bg-white absolute z-35" {...attributes}>
        {title && (
          <div className="px-5 py-3">
            <p className="text-fgray-400 text-base font-medium ">{title}</p>
          </div>
        )}
        {children}
        <div className="flex justify-end space-x-2 px-3.5">
          {onToggle && (
            <ActionButton onClick={onToggle}>
              {toggle || t("words.cancel")}
            </ActionButton>
          )}
          {onSubmit && (
            <ActionButton primary onClick={onSubmit}>
              {submit || t("words.apply")}
            </ActionButton>
          )}
        </div>
      </div>
    </>
  );
}

export default Dropdown;
