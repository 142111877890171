import { createContext } from "react";

export const PortalContext = createContext({
  deviceMode : "windows",
  setDeviceMode : () => {},
  ipadOnboarded: null,
  setIpadOnboarded: () => {},
  ipadOnboardRequested: false,
  setIpadOnboardRequested: () => {},
  macosOnboarded: null,
  setMacosOnboarded: ()=>{},
  windowsPolicyManagement: null,
  setWindowsPolicyManagement: ()=>{},
  ipadPolicyManagement: null,
  setIpadPolicyManagement: ()=>{},
  taskOnProgress:[],
  setTaskOnProgress:()=>{},
  apiToken:false,
  setApiToken:()=>{},
  numiatoStatus:"1000",
  setNumiatoStatus:()=>{},
  macosPolicyManagement:null,
  setMacosPolicyManagement:()=>{},
  refresh:0,
  makeRefresh:()=>{},
  categories : false,
  setCategories:()=>{},
  rooms:false,
  setRooms:()=>{},
  refreshGroups:0,
  makeRefreshGroups:()=>{}
})

export const DeviceStatusContext = createContext({
  deviceStatus : null,
  setDeviceStatus : () => {}
})