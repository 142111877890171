import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { PortalContext } from "../../PortalContext";

function CategoryCombo({
  setSelected,
  includeUnassigned,
  setGroupId,
  includeAllDevices,
}) {
  const { categories } = useContext(PortalContext);
  const { t } = useTranslation("global");
  function handleChange(event) {
    setGroupId
      ? setGroupId(event.target.value)
      : setSelected(event.target.value);
  }
  return (
    <select
      name="Categories"
      id="categories"
      onChange={handleChange}
      defaultValue={t("categories.modal.choose")}
      className="w-full rounded-xl border border-fgray-300 py-2 px-4 text-fblack"
    >
      <option value="0">{t("categories.modal.choose")}</option>
      {categories &&
        categories.map(({ id, displayName, groupId, shared }) => (
          <option value={setGroupId ? groupId : id} key={`cat_${id}`}>
            {shared
              ? t(
                  `categories.name.${displayName}`,
                  `${displayName.substring(7)} ${t("categories.name.shared")}`
                )
              : t(`categories.name.${displayName}`, displayName)}
          </option>
        ))}
      {includeUnassigned && (
        <option
          value={"00000000-0000-0000-0000-000000000000"}
          key={"cat_00000000-0000-0000-0000-000000000000"}
        >
          {t("categories.name.unassigned")}
        </option>
      )}
      {includeAllDevices && (
        <option
          value={"99999999-9999-9999-9999-999999999999"}
          key={"cat_99999999-9999-9999-9999-999999999999"}
        >
          {t("sidebar.all_devices")}
        </option>
      )}
    </select>
  );
}

export default CategoryCombo;
