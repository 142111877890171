import { useState } from "react";
import FilterChip from "../layout/FilterChip";
import { useTranslation } from "react-i18next";
import ActionButton from "../layout/buttons/ActionButton";
import { ReactComponent as ClearFilterIcon } from "../icons/clearFilter.svg";

function ApplicationFilterBar({ table, show }) {
  const [clean, setClean] = useState(false);
  const { t } = useTranslation("global");
  const clearFilter = () => {
    setClean((p) => !p);
  };
  const getItemOptions = (rows, column) => {
    return [
      ...new Set(
        rows.map((itm) => itm.original[column])
      ),
    ];
  };
  const handleObjectFilter = () => {};
  const getObjectfilterOptions = (rows, column) => {
    const targets = [...new Set(
      rows
        .map((row) => row.original[column].split(",").map((itm) => itm && itm))
        .flat()
    )];
    // return targets
    return [...targets.map((target) => ( target && {
      value: target,
      label: t(`categories.name.${target}`, target),
    }))];
  };
  return (
    show && (
      <>
        <div
          className={`gap-x-2 gap-y-2 w-full transition-all delay-1500 flex-wrap flex ${
            show ? "mt-4 mb-2" : "h-0 my-0"
          }`}
        >
          {show && table && (
            <>
              {table
                .getAllColumns()
                .map(
                  (column) =>
                    column.getCanFilter() && (
                      <FilterChip
                        key={`${column.id}${show}`}
                        label={column.columnDef.label}
                        columnName={column.id}
                        filter={column.getFilterValue()}
                        setFilter={column.setFilterValue}
                        options={
                          column.columnDef.columnFilterType === "option"
                            ? getItemOptions(
                                column.getFacetedRowModel().rows,
                                column.id
                              )
                            : column.columnDef.columnFilterType ===
                              "objectFilter"
                            ? getObjectfilterOptions(
                                column.getFacetedRowModel().rows,
                                column.id
                              )
                            : null
                        }
                        clear={clean}
                      />
                    )
                )}
              <ActionButton
                small
                subtle
                className="border border-fgray-300"
                Icon={ClearFilterIcon}
                onClick={clearFilter}
              >
                {t("words.clear_filter")}
              </ActionButton>
            </>
          )}
        </div>
      </>
    )
  );
}

export default ApplicationFilterBar;
