import { isEqual } from "lodash";
import { useState, useEffect, useCallback, useContext } from "react";
import { getApi } from "../tools/axiosInstances";
import { PortalContext } from "../PortalContext";

const initialize = () => {
  const allRooms = JSON.parse(sessionStorage.getItem("allRooms"));
  return allRooms || [];
};

export const useRoom = () => {
  const { rooms, setRooms, refreshGroups, refresh, apiToken } = useContext(PortalContext);
  const handleFetchRooms = useCallback(async () => {
    apiToken && getApi("/room/", {}).then((list) => {
      if (!isEqual(list.data, rooms)) {
        setRooms(list.data);
        sessionStorage.setItem("allRooms", JSON.stringify(list.data));
      }
    });
  }, [apiToken, rooms, setRooms]);
  useEffect(() => {
    if(!apiToken) return
    handleFetchRooms();
  }, [refreshGroups, refresh, apiToken]);

  return { rooms };
};
