import { useContext, useState } from "react";
import { ApplicationContext } from "../applicationContext";
import Dropdown from "../../layout/design/Dropdown";
import DropdownItem from "../../layout/design/DropdownItem";
import { useTranslation } from "react-i18next";
import { ReactComponent as Chevron } from "../../icons/arrow.svg";
import { ReactComponent as Arrow } from "../../icons/arrowBack.svg";

function MembersPickupMenu({ onSelect }) {
  const { members } = useContext(ApplicationContext);
  const { t } = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);
  const [secondLevel, setSecondLevel] = useState("");
  const [secondLevelMembers, setSecondLevelMembers] = useState([]);
  const [secondLevelVisible, setSecondLevelVisible] = useState([]);
  const firstLevel = Object.keys(members);

  const handleOnClickFirstLevel = (selected) => {
    setIsOpen(true);
    setSecondLevel(selected);
    setSecondLevelMembers(members[selected]);
    setSecondLevelVisible(members[selected]);
  };
  const handleOnClickBack = () => {
    setSecondLevel("");
    setSecondLevelMembers([]);
    setSecondLevelVisible([]);
    setIsOpen(false);
  };
  const handleOnChangeSearch = (searchValue) => {
    if (!searchValue) {
      setSecondLevelVisible(secondLevelMembers);
    } else {
      setSecondLevelVisible(
        secondLevelMembers.filter((it) =>
          it.displayName.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    }
  };
  const handleOnSelect = (member) => {
    onSelect(member);
    handleOnClickBack();
  };

  return (
    <>
      <Dropdown>
        {!isOpen && (
          <>
            <DropdownItem
              className={`hover:bg-fgray-100 truncate overflow-hidden max-w-[250px] transition-transform duration-500 ease-in-out capitalize`}
              onClick={() =>
                handleOnSelect({
                  type: "global",
                  id: "99999999-9999-9999-9999-999999999999",
                  displayName: "Global",
                })
              }
            >
              {t("categories.name.Global")}
            </DropdownItem>
            {firstLevel.map((it,index) => (
              <DropdownItem
                key={`fli_${index}`}
                onClick={() => handleOnClickFirstLevel(it)}
                className={`hover:bg-fgray-100 truncate overflow-hidden max-w-[250px] transition-transform duration-500 ease-in-out capitalize  ${
                  !isOpen ? "translate-x-0" : "-translate-x-full opacity-0"
                } `}
              >
                <div className="flex justify-between w-full ">
                  <span>{`${t(`words.${it}`, { count: members[it].length })} (${
                    members[it].length
                  })`}</span>
                  <Chevron className="rotate-90" />
                </div>
              </DropdownItem>
            ))}
          </>
        )}
        {isOpen && (
          <>
            <DropdownItem
              className={`hover:bg-fgray-100 truncate overflow-hidden max-w-[250px] transition-transform duration-500 ease-in-out capitalize ${
                isOpen ? "translate-x-0" : "-translate-x-full opacity-0"
              } `}
            >
              <div className="flex w-full ">
                <Arrow onClick={handleOnClickBack} />
                <input
                  placeholder={t(`words.${secondLevel}`, {
                    count: members[secondLevel].length,
                  })}
                  className="w-full border-0 focus:outline-none bg-inherit"
                  onChange={(e) => handleOnChangeSearch(e.target.value)}
                />
              </div>
            </DropdownItem>
            <div className="max-h-[200px] overflow-y-auto">
              {secondLevelVisible.map((op,index) => (
                <DropdownItem
                key={`sli_${index}`}
                  className={`hover:bg-fgray-100 truncate max-w-[240px] transition-transform duration-500 ease-in-out capitalize ${
                    isOpen ? "translate-x-0" : "-translate-x-full opacity-0"
                  } `}
                  onClick={() => handleOnSelect({ type: secondLevel, ...op })}
                >
                  {t(`categories.name.${op.displayName}`, op.displayName)}
                </DropdownItem>
              ))}
            </div>
          </>
        )}
      </Dropdown>
    </>
  );
}

export default MembersPickupMenu;
