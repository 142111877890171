import React from "react";
import Tooltip from "../tooltip/Tooltip";
import { useTranslation } from "react-i18next";

function FieldArea({ title, onEnter, invalid, loading, value, ...attributes }) {
  const { t } = useTranslation("global");
  return (
    <>
      <div className="w-full">
        {title && (
          <div className="font-normal text-sx text-fgray-300 my-1">{title}</div>
        )}
        <textarea
          className={`w-full rounded-lg border p-2 align-middle justify-start text-base font-normal ${
            invalid
              ? `bg-red-300 text-red-800`
              : `text-fgray-400 focus:text-fblack enabled:hover:text-fblack`
          } focus:outline-fgray-400 focus:outline-1 border-fgray-300   enabled:hover:shadow ${
            loading && "animate-pulse"
          }`}
          onKeyUp={(e) => {
            if (e.code === "Enter") {
              onEnter && onEnter();
            }
          }}
          value={value}
          {...attributes}
        />
      </div>
    </>
  );
}

export default FieldArea;
