import React, { useEffect, useCallback } from "react";

export const useToggleClickOutside = (ref, toggle) => {
  const memoizedToggle = useCallback(toggle, [toggle]);
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref?.current && !ref?.current.contains(e.target)) {
        memoizedToggle();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref,memoizedToggle]);
};

export const useToggleEscapeKey = (ref,toggle)=>{
  const memoizedToggle = useCallback(toggle, [toggle]);
  useEffect(() => {
    const handleEscapeKey = (e) => {
      if (e.key === "Escape") {
        memoizedToggle();
      }
    };
    document.addEventListener("keyup", handleEscapeKey);
    return () => {
      document.removeEventListener("keyup", handleEscapeKey);
    };
  }, [ref,memoizedToggle]);
}

export const useToggleComponent = (ref,toggle)=>{
  useToggleClickOutside(ref,toggle)
  useToggleEscapeKey(ref,toggle)
}