import { isEqual } from "lodash";
import { useState, useEffect, useContext, useCallback } from "react";
import { getApi } from "../tools/axiosInstances";
import { PortalContext } from "../PortalContext";

const initialize = () => {
  const allCategories = JSON.parse(sessionStorage.getItem("allCategories"));
  return allCategories || [];
};

export const useCategory = () => {
  const { categories, setCategories, refreshGroups, apiToken } =
    useContext(PortalContext);
  const handleFetchCategories = useCallback(async () => {
    apiToken &&
      getApi("/category/", {}).then((list) => {
        if (!isEqual(list.data, categories)) {
          setCategories(list.data);
          sessionStorage.setItem("allCategories", JSON.stringify(list.data));
        }
      });
  }, [apiToken, setCategories, categories]);
  useEffect(() => {
    if (!apiToken) return;
    handleFetchCategories();
  }, [refreshGroups, apiToken]);

  return { categories };
};
