import React, { useState, useRef, useEffect, useContext } from "react";
import AppAssignModal from "./AppAssignModal";
import AppProgressBar from "./AppProgressBar";
import AppDownloadModal from "./AppDownloadModal";
import ActionButton from "../layout/buttons/ActionButton";
import { useToggleComponent } from "../../tools/hooks";
import StandardButton from "../layout/buttons/StandardButton";
import { useTranslation } from "react-i18next";
import { ReactComponent as Download } from "../icons/download.svg";
import { ReactComponent as Update } from "../icons/sync.svg";
import { ReactComponent as Assign } from "./../icons/appAssign.svg";
import AppUpdateModal from "./AppUpdateModal";
import ModalCard, {
  ModalCardBody,
  ModalCardButtons,
  ModalCardTitle,
} from "../layout/ModalCard";
import { PortalContext } from "../../PortalContext";
function AppCard({ info }) {
  const { t } = useTranslation("global");
  const { makeRefresh } = useContext(PortalContext);
  const [assignModal, setAssignModal] = useState(false);
  const [installModal, setInstallModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [task, setTask] = useState(info.taskId);
  const toggleInstall = () => setInstallModal(!installModal);
  const toggleUpdate = () => setUpdateModal(!updateModal);
  const toggleAssign = () => setAssignModal(!assignModal);

  const Action = () => {
    if (
      task !== "00000000-0000-0000-0000-000000000000" &&
      task !== "installed"
    ) {
      // there is a task assigned
      return (
        <>
          <AppProgressBar
            taskId={task}
            setTaskId={setTask}
            reload={makeRefresh}
          />
        </>
      );
    } else if (info.updatable && task !== "installed") {
      return (
        <>
          <ActionButton
            onClick={toggleUpdate}
            id="w"
            Icon={Update}
            full
            subtle
            tooltip="tooptip.appstore.buttons.update"
          >
            {t("pages.appstore.actions.update")}
          </ActionButton>
        </>
      );
    } else if (info.isInstalled || task == "installed") {
      return (
        <>
          <ActionButton
            full
            subtle
          >
            {t("words.added")}
          </ActionButton>
        </>
      );
    } else
      return (
        <>
          <ActionButton
            onClick={toggleInstall}
            id="w"
            Icon={Download}
            full
            subtle
            tooltip="tooptip.appstore.buttons.download"
          >
            {t("pages.appstore.actions.download")}
          </ActionButton>
        </>
      );
  };

  useEffect(() => {
    if (task == "Installed") {
      info.isInstalled = true;
    }
  }, [task]);

  function AppDescription({ info }) {
    const [isReadMore, setIsReadMore] = useState(false);
    const hideReadMore = () => setIsReadMore(false);
    const showReadMore = () => setIsReadMore(true);
    const wrapper = useRef(null);
    useToggleComponent(wrapper, hideReadMore);

    return (
      <>
        <div>
          {info.description.length > 85
            ? `${info.description.slice(0, 85)}...`
            : info.description}
          <span
            onClick={showReadMore}
            className="text-forange-400 cursor-pointer"
          >
            {info.description.length > 85 ? ` ${t("words.more")}` : ""}
          </span>
        </div>
        {isReadMore && (
          <ModalCard wrapper={wrapper}>
            <ModalCardTitle>{info.display_name}</ModalCardTitle>
            <ModalCardBody>{info.description}</ModalCardBody>
            <ModalCardButtons>
              <StandardButton onClick={hideReadMore} subtle>
                {t("pages.device.modal.cancel")}
              </StandardButton>
            </ModalCardButtons>
          </ModalCard>
        )}
      </>
    );
  }
  return (
    <>
      <div
        id={info.name}
        className="border rounded-2xl object-center text-center justify-start w-64 m-2 p-4 gap-2 space-y-3 divide-y"
      >
        <div>
          <div className="justify-center items-center h-24 gap-2">
            <div className="justify-center align-middle mb-2 flex items-center ">
              <img className="h-12" src={info.logo} alt={info.name} />
            </div>

            <p className="h-12 text-base/5 align-text-bottom line-clamp-2">
              {info.display_name}
            </p>
          </div>
          <div className="h-24">
            <div className="text-fgray-300 text-sm">
              <AppDescription info={info} />
            </div>
          </div>
        </div>
        <div className="px-2/5 py-2">
          <Action />
        </div>
      </div>
      {installModal && (
        <AppDownloadModal
          app={info}
          toggle={toggleInstall}
          setTaskId={setTask}
        />
      )}
      {updateModal && (
        <AppUpdateModal app={info} toggle={toggleUpdate} setTaskId={setTask} />
      )}
      {assignModal && <AppAssignModal app={info} toggle={toggleAssign} />}
    </>
  );
}

export default AppCard;
