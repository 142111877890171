import { useContext, useEffect, useState, useRef } from "react";
import { ApplicationContext } from "../applicationContext";
import Chip from "../../layout/design/Chip";
import { ReactComponent as CircleX } from "../../icons/circleX.svg";
import { ReactComponent as CategoryIcon } from "../../icons/categoryIcon.svg";
import { ReactComponent as RoomIcon } from "../../icons/roomIcon.svg";
import { ReactComponent as DeviceIcon } from "../../icons/computer.svg";
import { ReactComponent as DefaultIcon } from "../../icons/squares.svg";
import { ReactComponent as SearchIcon } from "../../icons/magnifyingGlass.svg";
import MembersPickupMenu from "./MembersPickupMenu";
import { useToggleComponent } from "../../../tools/hooks";
import { useTranslation } from "react-i18next";

function MembersListField({ selected, onChange, refresh, fixed }) {
  // selected is an array of {id:uuid,displayName:str}for the members
  const [items, setItems] = useState(selected);
  const [showMenu, setShowMenu] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const menuRef = useRef(null);
  const { t } = useTranslation("global");
  useEffect(() => {
    setItems(selected);
    setShowMenu(false);
  }, [selected, refresh]);
  const handleOnRemove = (item) => {
    setItems((it) => it.filter((i) => i.id !== item.id));
    onChange && onChange("remove", item);
  };
  const handleOnSelectMember = (item) => {
    setItems((prev) => {
      if (!prev.some((existingItem) => existingItem.id === item.id)) {
        const neu = [...prev, item];
        onChange && onChange("add", item);
        return neu;
      }
      return prev;
    });
    hideMenu();
  };
  const handleOnClickList = (event) => {
    if (event.target.closest(".chip-item")) return;
    const container = document.querySelector(".chip-container");
  const chips = container.querySelectorAll(".chip-element");

  let lastVisibleChip = null;
  let hasHiddenChips = false;

  for (let i = chips.length - 1; i >= 0; i--) {
    const rect = chips[i].getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();

    if (rect.bottom <= containerRect.bottom) {
      lastVisibleChip = chips[i];
      break;
    } else {
      hasHiddenChips = true; // There are more chips than the last visible one
    }
  }

  let menuY;
  if (hasHiddenChips) {
    menuY = container.getBoundingClientRect().bottom; // Place at container bottom
  } else if (lastVisibleChip) {
    menuY = lastVisibleChip.getBoundingClientRect().bottom; // Place after last visible chip
  } else {
    menuY = container.getBoundingClientRect().bottom; // Fallback to container bottom
  }

  setMenuPosition({ x: fixed ? container.getBoundingClientRect().left + 10: 20, y: menuY });
  setShowMenu(true);
  };
  const hideMenu = () => setShowMenu(false);
  useToggleComponent(menuRef, hideMenu);
  const ItemAvatar = (itemType) => {
    switch (itemType) {
      case "category":
        return <CategoryIcon className="h-6 w-6" />;
        break;
      case "room":
        return <RoomIcon className="h-6 w-6" />;
        break;
      case "device":
        return <DeviceIcon className="h-6 w-6" />;
        break;
      case "search":
        return <SearchIcon className="h-6 w-6" />;
        break;
      default:
        return <DefaultIcon className="h-6 w-6" />;
        break;
    }
  };
  return (
    <>
      <div
        className="h-[168px] rounded-md w-full border border-fgray-300 p-2 overflow-y-auto chip-container"
        onClick={handleOnClickList}
        tabIndex={0}
      >
        <div className="flex flex-wrap gap-2 items-start overflow-y-auto  ">
          {items.map((item, index) => (
            <Chip key={index} className="chip-item chip-element capitalize cursor-pointer ">
              {ItemAvatar(item.type)}
              {t(`categories.name.${item.displayName}`, item.displayName)}
              <CircleX
                className="h-6 w-6 "
                onClick={() => handleOnRemove(item)}
              />
            </Chip>
          ))}
          {
            <Chip className="cursor-pointer chip-element" onClick={handleOnClickList}>
              {ItemAvatar("search")}
              {t("actions.click_here")}
            </Chip>
          }
        </div>
      </div>
      {showMenu && (
        <div
          className={`${fixed ? "fixed" : "absolute"} z-50 bg-white shadow-lg border border-gray-300 rounded-md chip-item `}
          style={{
            top: `${menuPosition.y}px`,
            // left: `20px`,
            left: `${menuPosition.x}px`,
          }}
          ref={menuRef}
        >
          <MembersPickupMenu onSelect={handleOnSelectMember} />
        </div>
      )}
    </>
  );
}

export default MembersListField;
