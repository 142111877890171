import { useTranslation } from "react-i18next";
import Drawer, {
  DrawerTitle,
  DrawerBody,
  DrawerButtons,
} from "../layout/design/Drawer";
import IconButton from "../layout/buttons/IconButton";
import { ReactComponent as SaveIcon } from "../icons/checkCircle.svg";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import OptionsBar from "../layout/OptionsBar";
import MembersListField from "./sidePanel/MembersListField";
import ModalCard, {
  ModalCardBody,
  ModalCardButtons,
  ModalCardTitle,
} from "../layout/ModalCard";
import BaseButton from "../layout/buttons/BaseButton";
import { ApplicationContext } from "./applicationContext";
import { formatBytes } from "../../tools/utilties";
import AppUpdateModal from "../appstore/AppUpdateModal";
function ApplicationDrawer({ app, isOpen, onClose, onSave }) {
  const [appInfo, setAppInfo] = useState(app);
  const { pendingChanges, setPendingChanges } = useContext(ApplicationContext);
  const [changes, setChanges] = useState({});
  const [updateModal, setUpdateModal] = useState(false);
  const showUpdateModal = () => setUpdateModal(true);
  const hideUpdateModal = () => setUpdateModal(false);
  const { t } = useTranslation("global");
  useEffect(() => {
    setAppInfo(app);
    setChanges({});
  }, [app]);
  const handleOnChangeInstallation = (tab, action, item) => {
    switch (tab) {
      case "installed":
      default:
        if (action === "add") {
          setChanges((p) => ({
            ...p,
            install: {
              ...p?.install,
              add: [...(p.install?.add || []), item],
            },
          }));
        }
        if (action === "remove") {
          setChanges((p) => ({
            ...p,
            install: {
              ...p?.install,
              remove: [...(p.install?.remove || []), item],
            },
          }));
        }
        break;
      case "uninstalled":
        if (action === "add") {
          setChanges((p) => ({
            ...p,
            uninstall: {
              ...p?.uninstall,
              add: [...(p.uninstall?.add || []), item],
            },
          }));
        }
        if (action === "remove") {
          setChanges((p) => ({
            ...p,
            uninstall: {
              ...p?.uninstall,
              remove: [...(p.uninstall?.remove || []), item],
            },
          }));
        }
        break;
    }
    setPendingChanges(true);
  };
  const handleOnSave = useCallback(() => {
    setAppInfo((prev) => {
      const newInfo = {
        ...prev,
        installed: [
          ...prev.installed.filter(
            (member) =>
              !changes.install?.remove?.some(
                (removed) => removed.id === member.id
              )
          ), // Remove members from installed
          ...(changes.install?.add || []), // Add new members to installed
        ],
        uninstalled: [
          ...prev.uninstalled.filter(
            (member) =>
              !changes.uninstall?.remove?.some(
                (removed) => removed.id === member.id
              )
          ), // Remove members from uninstalled
          ...(changes.uninstall?.add || []), // Add new members to uninstalled
        ],
      };

      onSave && onSave(prev, newInfo, changes);
      return newInfo;
    });
    setPendingChanges(false);
  }, [changes, onSave, setPendingChanges]);
  const add_and_remove_names = () => {
    const install = changes?.install?.add
      ? t("application.drawer.confirmSave.body_install", {
          displayName: changes?.install?.add
            ?.map((m) => t(`categories.name.${m?.displayName}`, m?.displayName))
            .join(", "),
        })
      : "";
    const uninstall = changes?.uninstall?.add
      ? t("application.drawer.confirmSave.body_uninstall", {
          displayName: changes?.uninstall?.add
            ?.map((m) => t(`categories.name.${m?.displayName}`, m?.displayName))
            .join(", "),
        })
      : "";
    const remove_uninstall = changes?.uninstall?.remove
      ? t("application.drawer.confirmSave.body_removeuninstall", {
          displayName: changes?.uninstall?.remove
            .map((m) => t(`categories.name.${m?.displayName}`, m?.displayName))
            .join(", "),
        })
      : "";
    const remove_install = changes?.install?.remove
      ? t("application.drawer.confirmSave.body_removeinstall", {
          displayName: changes?.install?.remove
            .map((m) => t(`categories.name.${m?.displayName}`, m?.displayName))
            .join(", "),
        })
      : "";
    const all = [install, uninstall, remove_uninstall, remove_install].filter(
      Boolean
    );
    return `${
      all.length > 1
        ? all.slice(0, -1).join(", ") +
          ` ${t("words.and")} ` +
          all[all.length - 1]
        : all[0] || ""
    }.`;
  };

  return (
    <>
      <Drawer isOpen={isOpen} onClose={onClose}>
        <DrawerTitle onClose={onClose}>
          <div className="flex w-full justify-between">
            <div
              className={`${appInfo?.appStatus === 301 ? "w-[39%]" : ""}  py-1`}
            >
              {appInfo?.displayName}
            </div>
            {appInfo?.appStatus == 301 && (
              <div className={`w-[61%]`}>
                <IconButton small secondary onClick={showUpdateModal}>
                  {t("actions.update_application")}
                </IconButton>
              </div>
            )}
          </div>
        </DrawerTitle>
        <DrawerBody>
          <ApplicationProperties appInfo={appInfo} />

          <AppDrawerInstallUninstallBox
            appInfo={appInfo}
            onChangeMembers={handleOnChangeInstallation}
          />
        </DrawerBody>
        <DrawerButtons>
          <IconButton
            Icon={SaveIcon}
            disabled={!pendingChanges}
            onClick={handleOnSave}
          >
            {t("words.save")}
          </IconButton>
        </DrawerButtons>
      </Drawer>
      {updateModal && (
        <AppUpdateModal
          app={appInfo}
          toggle={hideUpdateModal}
          setTaskId={() => {}}
        />
      )}
    </>
  );
}

function AppProperty({ title, value, noCaps, classNameTitle, classNameValue }) {
  return (
    <div className="w-full gap-2">
      <div
        className={`text-base font-medium text-fblack first-letter:capitalize ${classNameTitle}`}
      >
        {title}
      </div>
      <div
        className={`text-base font-normal  ${
          !noCaps && "first-letter:capitalize"
        } ${classNameValue ? classNameValue : "text-fgray-400"}`}
      >
        {value}
      </div>
    </div>
  );
}

function ApplicationProperties({ appInfo }) {
  const { t } = useTranslation("global");
  switch (appInfo?.appType) {
    case "win32LobApp":
      return (
        <div className="w-full space-y-4 my-4">
          <AppProperty
            title={t("properties.app_source")}
            value={t(`properties.application.${appInfo?.appSource}`, "-")}
          />
          <AppProperty
            title={t("properties.app_status")}
            value={t(`status.application.${appInfo?.appStatus}`, "-")}
            classNameValue={appInfo?.appStatus == 301 ? "text-fstatus-400" : ""}
          />
          <AppProperty
            title={t("properties.developer")}
            value={appInfo?.developer || "-"}
          />
          <AppProperty
            title={t("properties.version")}
            value={appInfo?.version || "-"}
          />
          <AppProperty
            title={t("properties.size")}
            value={formatBytes(appInfo?.size, 2)}
          />
        </div>
      );
      break;
    case "winGetApp":
    case "officeSuiteApp":
    case "windowsMicrosoftEdgeApp":
    default:
      return (
        <div className="w-full space-y-4 my-4">
          <AppProperty
            title={t("properties.app_source")}
            value={t(`properties.application.${appInfo?.appSource}`, "-")}
          />
          <AppProperty
            title={t("properties.app_status")}
            value={t(`status.application.${appInfo?.appStatus}`, "-")}
            classNameValue={appInfo?.appStatus == 301 ? "text-fstatus-400" : ""}
          />
          <AppProperty
            title={t("properties.developer")}
            value={appInfo?.developer || "-"}
          />
        </div>
      );
      break;
    case "windowsWebApp":
    case "webApp":
      return (
        <div className="w-full space-y-4 my-4">
          <AppProperty
            title={t("properties.app_source")}
            value={t(`properties.application.${appInfo?.appSource}`, "-")}
          />
          <AppProperty
            title={t("properties.app_status")}
            value={t(`status.application.${appInfo?.appStatus}`, "-")}
            classNameValue={appInfo?.appStatus == 301 ? "text-fstatus-400" : ""}
          />
          <AppProperty
            title={t("properties.developer")}
            value={appInfo?.developer || "-"}
          />
          <AppProperty
            noCaps
            title={t("properties.app_url")}
            value={appInfo?.appUrl || "-"}
          />
        </div>
      );
      break;
    case "iosVppApp":
    case "macOsVppApp":
      return (
        <div className="w-full space-y-4 my-4">
          <AppProperty
            title={t("properties.app_source")}
            value="Apple School Manager"
          />
          <AppProperty
            title={t("properties.publisher")}
            value={appInfo?.publisher || "-"}
          />
          <AppProperty
            title={t("properties.license_usage")}
            value={`${appInfo?.usedLicenseCount}/${appInfo?.totalLicenseCount}`}
          />
        </div>
      );
      break;
    case "iosStoreApp":
      return (
        <div className="w-full space-y-4 my-4">
          <AppProperty
            title={t("properties.app_source")}
            value={t("words.custom")}
          />
          <AppProperty
            title={t("properties.publisher")}
            value={appInfo?.publisher || "-"}
          />
        </div>
      );
      break;
    case "iosiPadOSWebClip":
    case "macOSWebClip":
      return (
        <div className="w-full space-y-4 my-4">
          <AppProperty
            title={t("properties.app_source")}
            value={t("words.custom")}
          />
          <AppProperty
            title={t("properties.publisher")}
            value={appInfo?.publisher || "-"}
          />
          <AppProperty
            noCaps
            title={t("properties.app_url")}
            value={appInfo?.appUrl || "-"}
          />
        </div>
      );
      break;
    case "macOSOfficeSuiteApp":
    case "macOSMicrosoftEdgeApp":
    case "macOSMicrosoftDefenderApp":
      return (
        <div className="w-full space-y-4 my-4">
          <AppProperty
            title={t("properties.app_source")}
            value={t(`properties.application.${appInfo?.appSource}`, "-")}
          />
          <AppProperty
            title={t("properties.publisher")}
            value={appInfo?.publisher || "-"}
          />
        </div>
      );
      break;
    case "macOSPkgApp":
    case "macOSDmgApp":
      return (
        <div className="w-full space-y-4 my-4">
          <AppProperty
            title={t("properties.app_source")}
            value={t(`properties.application.${appInfo?.appSource}`, "-")}
          />
          <AppProperty
            title={t("properties.publisher")}
            value={appInfo?.publisher || "-"}
          />
        </div>
      );
      break;
  }
}

function AppDrawerInstallUninstallBox({ appInfo, onChangeMembers }) {
  const [installed, setInstalled] = useState([]);
  const [uninstalled, setUninstalled] = useState([]);
  const [selectedTab, setSelectedTab] = useState("installed");
  const [warningVisible, setWarningVisible] = useState(false);
  const [allDevicesWarning, setAllDevicesWarning] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const warningModalWrapper = useRef(null);
  const allDevicesUninstallWarningWrapper = useRef(null);
  const makeRefresh = () => setRefresh((p) => p + 1);
  const showWarning = (action, member) =>
    setWarningVisible({ show: true, action, member });
  const hideWarning = () => {
    setWarningVisible(false);
    makeRefresh();
  };
  const showUninstallAllDevicesWarning = () => setAllDevicesWarning(true);
  const hideUninstallAllDevicesWarning = () => setAllDevicesWarning(false);
  const { t } = useTranslation("global");
  const handleOnChangeMembers = (action, member, tab = selectedTab) => {
    switch (tab) {
      case "installed":
      default:
        if (action === "add") {
          if (uninstalled.some((m) => m.id === member.id)) {
            showWarning("add", member);
          } else {
            handleConfirmChangeMembers("add", member);
          }
        }
        if (action === "remove") {
          handleConfirmChangeMembers("remove", member);
        }
        break;
      case "uninstalled":
        if (action === "add") {
          if (member.id == "99999999-9999-9999-9999-999999999999") {
            showUninstallAllDevicesWarning();
          } else if (installed.some((m) => m.id === member.id)) {
            showWarning("add", member);
          } else {
            handleConfirmChangeMembers("add", member);
          }
        }
        if (action === "remove") {
          handleConfirmChangeMembers("remove", member);
        }
        break;
    }
  };
  const handleConfirmChangeMembers = (action, member, tab = selectedTab) => {
    onChangeMembers(tab, action, member);
    switch (tab) {
      case "installed":
      default:
        if (action === "add") {
          setInstalled((p) => [...p, member]);
          if (uninstalled.some((m) => m.id === member.id)) {
            setUninstalled((p) => p.filter((i) => i.id !== member.id));
            onChangeMembers("uninstalled", "remove", member);
          }
        }
        if (action === "remove") {
          setInstalled((p) => p.filter((i) => i.id !== member.id));
          // onChangeMembers("installed", "remove", member);
        }
        break;
      case "uninstalled":
        if (action === "add") {
          setUninstalled((p) => [...p, member]);
          if (installed.some((m) => m.id === member.id)) {
            setInstalled((p) => p.filter((i) => i.id !== member.id));
            onChangeMembers("installed", "remove", member);
          }
        }
        if (action === "remove") {
          setUninstalled((p) => p.filter((i) => i.id !== member.id));
          // onChangeMembers("uninstalled", "remove", member)
        }
        break;
    }
  };
  const handleConfirmChange = (action, member) => {
    handleConfirmChangeMembers(action, member);
    hideWarning();
    makeRefresh();
  };
  const handleConfirmUninstallAll = () => {
    installed.forEach((member) => {
      handleConfirmChangeMembers("remove", member, "installed");
    });
    handleConfirmChangeMembers(
      "add",
      {
        type: "global",
        id: "99999999-9999-9999-9999-999999999999",
        displayName: "Global",
      },
      "uninstalled"
    );
    hideUninstallAllDevicesWarning();
  };
  useEffect(() => {
    setInstalled(appInfo.installed);
    setUninstalled(appInfo.uninstalled);
    setSelectedTab("installed");
  }, [appInfo]);
  return (
    <>
      <div className="space-y-2">
        <OptionsBar
          options={[
            {
              value: "installed",
              label: `${t("application.drawer.tab.installed")} (${
                installed.length
              })`,
            },
            {
              value: "uninstalled",
              label: `${t("application.drawer.tab.uninstalled")} (${
                uninstalled.length
              })`,
            },
          ]}
          onSelect={setSelectedTab}
          selected={selectedTab}
        />
        {selectedTab === "installed" ? (
          <MembersListField
            selected={installed}
            onChange={handleOnChangeMembers}
            refresh={refresh}
          />
        ) : (
          selectedTab === "uninstalled" && (
            <MembersListField
              selected={uninstalled}
              onChange={handleOnChangeMembers}
              refresh={refresh}
            />
          )
        )}
      </div>
      {warningVisible?.show && (
        <ModalCard wrapper={warningModalWrapper}>
          <ModalCardTitle>
            {t("application.drawer.toggleMember.title")}
          </ModalCardTitle>
          <ModalCardBody>
            {t("application.drawer.toggleMember.body", {
              displayName: t(
                `categories.name.${warningVisible.member?.displayName}`,
                warningVisible.member?.displayName
              ),
              action: selectedTab === "installed" ? "uninstall" : "install",
            })}
          </ModalCardBody>
          <ModalCardButtons>
            <BaseButton onClick={hideWarning} subtle>
              {t("words.cancel")}
            </BaseButton>
            <BaseButton
              onClick={() =>
                handleConfirmChange(
                  warningVisible.action,
                  warningVisible?.member
                )
              }
            >
              {t("actions.confirm")}
            </BaseButton>
          </ModalCardButtons>
        </ModalCard>
      )}
      {allDevicesWarning && (
        <ModalCard wrapper={allDevicesUninstallWarningWrapper}>
          <ModalCardTitle>
            {t("application.drawer.allDevicesUninstall.title")}
          </ModalCardTitle>
          <ModalCardBody>
            {t("application.drawer.allDevicesUninstall.body")}
          </ModalCardBody>
          <ModalCardButtons>
            <BaseButton onClick={hideUninstallAllDevicesWarning} subtle>
              {t("words.cancel")}
            </BaseButton>
            <BaseButton onClick={() => handleConfirmUninstallAll()}>
              {t("actions.confirm")}
            </BaseButton>
          </ModalCardButtons>
        </ModalCard>
      )}
    </>
  );
}

export default ApplicationDrawer;
