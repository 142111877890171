import React, { useState, useContext,useRef } from "react";
import { PortalContext } from "../../PortalContext";
import { ReactComponent as ComputerLogo } from "../../components/icons/computer.svg";
import { ReactComponent as TabletLogo } from "../../components/icons/tablet.svg";
import { ReactComponent as Arrow } from "../../components/icons/arrow.svg";
import { useToggleComponent } from "../../tools/hooks";
import { useTranslation } from "react-i18next";
function DeviceMode() {
  const { t } = useTranslation("global");
  const [expanded, setExpanded] = useState(false);
  const { deviceMode, setDeviceMode } = useContext(PortalContext);
  const expand = () => setExpanded(!expanded);
  
  const handleMode = (e) => {
    setDeviceMode(e);
    expand();
  };
  const wrapperRef = useRef(null)
  useToggleComponent(wrapperRef,setExpanded)

  const deviceModeLabel = ()=>{
    switch(deviceMode){
      case "windows":
        return <>
        <ComputerLogo className="h-6 w-6 m-auto" />
        <p className="text-center px-2 truncate m-auto">
          {t("pages.home.windows")}
        </p>
      </>
        break;
      case "ipad":
        return <>
        <TabletLogo className="h-6 w-6 m-auto" />
        <p className="text-center px-2 truncate m-auto">
          {t("pages.home.ipad")}
        </p>
      </>
        break;
      case "macos":
        return <>
        <ComputerLogo className="h-6 w-6 m-auto" />
        <p className="text-center px-2 truncate m-auto">
          {t("words.macos")}
        </p>
      </>
        break;
    }
  }

  return (
    <>
      <div className="relative" ref={wrapperRef}>
        <div
          className="flex z-1 static rounded cursor-pointer hover:bg-fgray-100 p-2 my-1"
          onClick={expand}
        >
          {deviceModeLabel()}
          <Arrow className="mt-1 rotate-180" />
        </div>
        {expanded && (
          <div className=" p-2 absolute float border w-[140px] shadow-lg rounded-lg bg-white z-50 cursor-pointer">
            <div
              className=" p-2 flex  m-auto hover:font-semibold hover:bg-forange-100 hover:text-forange-400 hover:border-forange-400 rounded "
              id="windows"
              onClick={()=>handleMode("windows")}
            >
              <ComputerLogo className="h-6 w-6 " />
              <p className=" truncate pl-2">{t("pages.home.windows")}</p>
            </div>
            <div
              className=" p-2 flex  m-auto hover:font-semibold hover:bg-forange-100 hover:text-forange-400 hover:border-forange-400 rounded "
              id="macos"
              onClick={()=>handleMode("macos")}
            >
              <ComputerLogo className="h-6 w-6 " />
              <p className=" truncate pl-2">{t("words.macos")}</p>
            </div>
            <div
              className=" p-2 flex  m-auto hover:font-semibold hover:bg-forange-100 hover:text-forange-400 hover:border-forange-400 rounded "
              id="ipad"
              onClick={()=>handleMode("ipad")}
            >
              <TabletLogo className="h-6 w-6" />
              <p className=" truncate pl-2 ">{t("pages.home.ipad")}</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default DeviceMode;
