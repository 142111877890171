import React from "react";
import { useTranslation } from "react-i18next";
import { formatBytes } from "../../tools/utilties";
import { ReactComponent as CircleCheckIcon } from "../icons/checkCircle.svg";
import { ReactComponent as CircleXIcon } from "../icons/circleX.svg";
import { ReactComponent as LockIcon } from "../icons/lock-closed.svg";
import { ReactComponent as LockOpenIcon } from "../icons/lock-open.svg";
function SystemInfoBox({ deviceInfo }) {
  const { t } = useTranslation("global");
  return (
    <div className="w-full space-y-2.5 rounded-lg border-fgray-200 border ">
      <SystemBoxField
        title={t("pages.device_detail.os")}
        value={deviceInfo.operatingSystem}
      />
      <SystemBoxField
        title={t("pages.device_detail.os_version")}
        value={deviceInfo.operatingSystemVerion}
      />
      {deviceInfo.type == "windows" && (
        <SystemBoxField
          title={t("pages.device_detail.os_edition")}
          value={deviceInfo.operatingSystemEdition}
        />
      )}
      {deviceInfo.type == "windows" && (
        <SystemBoxField
          title={t("pages.device_detail.os_language")}
          value={deviceInfo.operatingSystemLang}
        />
      )}
      <SystemBoxField
        title={t("pages.device_detail.manufacturer")}
        value={deviceInfo.manufacturer}
      />
      <SystemBoxField
        title={t("pages.device_detail.model")}
        value={t(`device.${deviceInfo.model}.displayName`,deviceInfo.model)}
      />
      {deviceInfo.type == "windows" && (
        <SystemBoxField
          title={t("pages.device_detail.processor")}
          value={deviceInfo.processorArchitecture}
        />
      )}
      {deviceInfo.type == "windows" && (
        <SystemBoxField
          title={t("pages.device_detail.device_memory")}
          value={deviceInfo.physicalMemoryInBytes > 0 ? formatBytes(deviceInfo.physicalMemoryInBytes,0) : "-"} 
        />
      )}
      <SystemBoxField
        title={t("pages.device_detail.free_storage")}
        value={deviceInfo}
        storage
      />
      {deviceInfo.type == "ipad" && (
        <SystemBoxField
          title={t("pages.device_detail.shared_mode")}
          value={deviceInfo.isSharedDevice}
          boolean
        />
      )}
      {deviceInfo.type == "windows" && (<SystemBoxField
        title={t("pages.device_detail.ipAddress")}
        value={deviceInfo.ipAddressV4}
        icon
      />)}
      {deviceInfo.type == "windows" && (<SystemBoxField
        title={t("pages.device_detail.wiredIpAddresses")}
        value={deviceInfo.wiredIPv4Addresses}
        multiline
        icon
      />)}
      {deviceInfo.type == "windows" && (<SystemBoxField
        title={t("pages.device_detail.subnetAddress")}
        value={deviceInfo.subnetAddress}
        icon
      />)}
      <SystemBoxField
        title={t("pages.device_detail.wifi_mac")}
        value={deviceInfo.wiFiMacAddress}
        icon
      />
      <SystemBoxField
        title={t("pages.device_detail.ethernet_mac")}
        value={deviceInfo.ethernetMacAddress}
        icon
      />
    </div>
  );
}

export default SystemInfoBox;

export const SystemBoxField = ({
  title,
  value,
  storage,
  boolean,
  icon,
  multiline,
  encryption,
}) => {
  const { t } = useTranslation("global");
  const StorageBar = ({ value }) => {
    const fullness = Math.round(
      (1 - value.freeStorage / value.totalStorage) * 100
    );
    return (
      <>
        <div className="space-x-4 flex">
          <div className="h-4 my-1 bg-fgray-400 w-16 rounded">
            <div
              className="h-4 bg-forange-400 rounded-l"
              style={{ width: fullness + "%" }}
            ></div>
          </div>
          <p className="">
            {formatBytes(value.totalStorage - value.freeStorage, 2) +
              " / " +
              formatBytes(value.totalStorage, 2)}
          </p>
        </div>
      </>
    );
  };
  return (
    <div className="w-full py-4 px-5 flex">
      <p className="font-medium w-1/2">{title}</p>
      {storage ? (
        <StorageBar value={value} />
      ) : encryption ? (
        <p className="text-fgray-400 truncate w-1/2 flex ">
          {value ? (
            <>
              <LockIcon className="mr-2 w-6 h-6" />
              {t("words.yes")}
            </>
          ) : (
            <>
              <LockOpenIcon className="mr-2 w-6 h-6" />
              {t("words.no")}
            </>
          )}
        </p>
      ) : boolean ? (
        <p className="text-fgray-400 truncate w-1/2">
          {icon ? (
            value ? (
              <CircleCheckIcon />
            ) : (
              <CircleXIcon />
            )
          ) : value ? (
            t("words.yes")
          ) : (
            t("words.no")
          )}
        </p>
      ) : multiline ? (
        <>
          <div className="text-fgray-400 truncate w-1/2  ">
            {value.length >0 ? value.map((val) => <p>{val}</p>):<CircleXIcon/>}
          </div>
        </>
      ) : (
        <p className="text-fgray-400 truncate w-1/2">
          {value || (icon && <CircleXIcon />)}
        </p>
      )}
    </div>
  );
};
