import { useTranslation } from "react-i18next";
import { SidebarItem } from "../layout/Sidebar";

function AppstoreSidebar({ selected, onChangeSelected, badge }) {
  const { t } = useTranslation("global");
  return (
    <div className="min-w-64 justify-end mr-5 mb-5">
      <nav className=" mr-5 w-64 h-[calc(100%-4rem)] overflow-y-auto ">
        <ul className="nav flex flex-col w-full">
          {/* allDevices */}
          <SidebarItem
            isSelected={selected === "all"}
            onSelect={() => onChangeSelected("all")}
          >
            {t("pages.appstore.tabs.all")}
          </SidebarItem>
          <SidebarItem
            isSelected={selected === "downloaded"}
            onSelect={() => onChangeSelected("downloaded")}
          >
            {t("pages.appstore.tabs.downloaded")}
          </SidebarItem>
          <SidebarItem
            isSelected={selected === "updatable"}
            onSelect={() => onChangeSelected("updatable")}
          >
            <div className="flex items-center gap-4">
              {t("pages.appstore.tabs.updatable")}
              {badge > 0 && <div className="w-7 h-7 text-forange-400 bg-forange-100 rounded-full justify-center items-center flex text-sm font-semibold">{badge}</div>}
            </div>
          </SidebarItem>
        </ul>
      </nav>
    </div>
  );
}

export default AppstoreSidebar;
