import React, { useState, useRef, useContext, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { postApi } from "../../tools/axiosInstances";
import StandardButton from "../layout/buttons/StandardButton";
import { useToggleComponent } from "../../tools/hooks";
import ModalCard, {
  ModalCardBody,
  ModalCardTitle,
  ModalCardButtons,
} from "../layout/ModalCard";
import ActionButton from "../layout/buttons/ActionButton";
import { PortalContext } from "../../PortalContext";
import MembersListField from "./sidePanel/MembersListField";
function ApplicationActionModalButton({
  selected,
  action,
  Icon,
  clear,
  filter,
  onSave,
}) {
  const { t } = useTranslation("global");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [members, setMembers] = useState([]);
  const { deviceMode } = useContext(PortalContext);
  const toggleModal = () => selected?.length > 0 && setShowModal((v) => !v);
  const hide = () => {
    setMembers([]);
    setShowModal(false);
  };
  const wrapper = useRef(null);
  useToggleComponent(wrapper, hide);
  const handleOnChangeMembers = useCallback((action, member) => {
    if (action === "add") {
      setMembers((p) => [...p, member]);
    }
    if (action === "remove") {
      setMembers((p) => p.filter((m) => m.id !== member.id));
    }
  }, []);
  const handleDeleteClick = useCallback(() => {
    setLoading(true);
    let reqBody = {
      kind: "del",
      target: "app",
      detail: selected,
    };
    
console.log(reqBody)
    postApi("/tasks/", reqBody).then((response) => {
      hide();
      setLoading(false);
      clear && clear();
    });
  }, [selected, clear]);
  const handleOnSave = useCallback(() => {
    setLoading(true);
    const updatedApps = selected.map((app) => {
      return {
        ...app,
        installed:
          action === "install"
            ? [
                ...app.installed.filter(
                  (member) =>
                    !members?.some((removed) => removed.id === member.id)
                ), // Remove members from installed
                ...(members || []), // Add new members to installed
              ]
            : app.installed,
        uninstalled:
          action === "uninstall"
            ? [
                ...app.uninstalled.filter(
                  (member) =>
                    !members?.some((removed) => removed.id === member.id)
                ), // Remove members from uninstalled
                ...(members || []), // Add new members to uninstalled
              ]
            : app.uninstalled,
      };
    });
    const changes = { [action]: { add: members, remove: [] } };
    onSave && onSave(selected, changes);
    hide();
    setLoading(false);
    clear && clear();
  }, [clear, members, onSave, selected, action]);
  return (
    <>
      <ActionButton
        id={action}
        Icon={Icon}
        onClick={toggleModal}
        tooltip={`tooltip.application.${deviceMode}.${action}`}
        subtle
      >
        {t(`pages.application.${action}`)}
      </ActionButton>
      {showModal &&
        selected.length > 0 &&
        (action === "delete" ? (
          <>
            <ModalCard wrapper={wrapper}>
              <ModalCardTitle>
                {t(`pages.application.modal.title_delete`, {
                  displayName: selected[0].displayName,
                  count: selected.length,
                })}
              </ModalCardTitle>
              <ModalCardBody>
                {t(`pages.application.modal.confirm_delete`, {
                  displayName: selected[0].displayName,
                  count: selected.length,
                })}
              </ModalCardBody>
              <ModalCardButtons>
                <StandardButton onClick={hide} subtle>
                  {t("pages.application.modal.cancel")}
                </StandardButton>
                <StandardButton onClick={handleDeleteClick} loading={loading}>
                  {t(`pages.application.modal.delete`)}
                </StandardButton>
              </ModalCardButtons>
            </ModalCard>
          </>
        ) : (
          <ModalCard wrapper={wrapper} wide>
            <ModalCardTitle>
              {t(`pages.application.modal.title_${action}`, {
                appName: selected[0].displayName,
                count: selected.length,
              })}
            </ModalCardTitle>
            <ModalCardBody className="space-y-1">
              <div>
                {t(`pages.application.modal.${action}`, {
                  displayName: selected[0].displayName,
                  count: selected.length,
                })}
              </div>
              <MembersListField
                selected={members}
                onChange={handleOnChangeMembers}
                fixed
              />
            </ModalCardBody>
            <ModalCardButtons>
              <StandardButton onClick={hide} subtle>
                {t("pages.application.modal.cancel")}
              </StandardButton>
              <StandardButton onClick={handleOnSave} loading={loading}>
                {t(`pages.application.modal.${action}`)}
              </StandardButton>
            </ModalCardButtons>
          </ModalCard>
        ))}
    </>
  );
}

export default ApplicationActionModalButton;
