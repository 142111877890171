import { useCallback, useContext, useState } from "react";
import { PortalContext } from "../PortalContext";
import { getApi, postApi } from "../tools/axiosInstances";
import { useEffect } from "react";
import { useDevice } from "./useDevice";
import { isEqual } from "lodash";

const initialize = (target) => {
  let key = "windowsApplications";
  switch (target) {
    case "windows":
    default:
      key = "windowsApplications";
      break;
    case "ipad":
      key = "ipadApplications";
      break;
    case "macos":
      key = "macosApplications";
      break;
  }
  const info = JSON.parse(sessionStorage.getItem(key));
  return info || false;
};

export const useApplication = () => {
  const [windowsApplications, setWindowsApplications] = useState(
    initialize("windows")
  );
  const [ipadApplications, setIpadApplications] = useState(initialize("ipad"));
  const [macosApplications, setMacosApplications] = useState(
    initialize("macos")
  );
  const [members, setMembers] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { windowsData, ipadData, macosData, entraData } = useDevice();
  const {
    refresh,
    deviceMode,
    macosOnboarded,
    ipadOnboarded,
    categories,
    rooms,
  } = useContext(PortalContext);

  const fetchApplications = useCallback(async () => {
    setIsLoading(true);
    const promises = [];
    const windowsApps = getApi(`/application/windows/`, {}).then((response) => {
      if (
        !isEqual(
          JSON.stringify(response.data),
          sessionStorage.getItem("windowsApplications")
        )
      ) {
        setWindowsApplications(response.data);
        sessionStorage.setItem(
          "windowsApplications",
          JSON.stringify(response.data)
        );
      }
    });
    promises.push(windowsApps);
    if (ipadOnboarded) {
      const ipadApps = getApi(`/application/ipad/`, {}).then((response) => {
        if (
          !isEqual(
            JSON.stringify(response.data),
            sessionStorage.getItem("ipadApplications")
          )
        ) {
          setIpadApplications(response.data);
          sessionStorage.setItem(
            "ipadApplications",
            JSON.stringify(response.data)
          );
        }
      });
      promises.push(ipadApps);
    } 
    // else {
    //   setIpadApplications([]);
    // }
    if (macosOnboarded) {
      const macosApps = getApi(`/application/macos/`, {}).then((response) => {
        if (
          !isEqual(
            JSON.stringify(response.data),
            sessionStorage.getItem("macosApplications")
          )
        ) {
          setMacosApplications(response.data);
          sessionStorage.setItem(
            "macosApplications",
            JSON.stringify(response.data)
          );
        }
      });
      promises.push(macosApps);
    } 
    // else {
    //   setMacosApplications([]);
    // }
    await Promise.all(promises);
    setIsLoading(false);
  }, [ipadOnboarded, macosOnboarded]);
  useEffect(() => {
    fetchApplications();
  }, [refresh, fetchApplications, ipadOnboarded,macosOnboarded]);
  useEffect(() => {
    switch (deviceMode) {
      case "windows":
      default:
        setMembers({
          category: categories
            ? categories.map((cat) => ({
                id: cat.groupId,
                displayName: cat.displayName,
              }))
            : [],
          room: rooms
            ? rooms.map((room) => ({
                id: room.id,
                displayName: room.displayName,
              }))
            : [],
          device: windowsData
            ? windowsData.map((device) => ({
                id: entraData.find(
                  (entra) => entra.deviceId === device.azureADDeviceId
                )?.id,
                displayName: device.deviceName,
              }))
            : [],
        });
        break;
      case "ipad":
        setMembers({
          category: categories
            ? categories.map((cat) => ({
                id: cat.groupId,
                displayName: cat.displayName,
              }))
            : [],
          room: rooms
            ? rooms.map((room) => ({
                id: room.id,
                displayName: room.displayName,
              }))
            : [],
          device: ipadData
            ? ipadData.map((device) => ({
                id: entraData.find(
                  (entra) => entra.deviceId === device.azureADDeviceId
                )?.id,
                displayName: device.deviceName,
              }))
            : [],
        });
        break;
      case "macos":
        setMembers({
          category: categories
            ? categories.map((cat) => ({
                id: cat.groupId,
                displayName: cat.displayName,
              }))
            : [],
          room: rooms
            ? rooms.map((room) => ({
                id: room.id,
                displayName: room.displayName,
              }))
            : [],
          device: macosData
            ? macosData.map((device) => ({
                id: entraData.find(
                  (entra) => entra.deviceId === device.azureADDeviceId
                )?.id,
                displayName: device.deviceName,
              }))
            : [],
        });
        break;
    }
  }, [
    categories,
    rooms,
    windowsData,
    ipadData,
    macosData,
    deviceMode,
    entraData,
  ]);

  const saveChangesLocal = useCallback(
    (appInformation) => {
      appInformation.map((a) => {
        switch (deviceMode) {
          case "windows":
          default:
            setWindowsApplications((prevApplications) =>
              prevApplications.map((app) =>
                app.id === a.id ? { ...app, ...a } : app
              )
            );
            break;
          case "ipads":
            setIpadApplications((prevApplications) =>
              prevApplications.map((app) =>
                app.id === a.id ? { ...app, ...a } : app
              )
            );
            break;
          case "macos":
            setMacosApplications((prevApplications) =>
              prevApplications.map((app) =>
                app.id === a.id ? { ...app, ...a } : app
              )
            );
            break;
        }
      });
    },
    [deviceMode]
  );

  const saveChanges = useCallback(async (targets, changes) => {
    let reqBody = {
      kind: "asg",
      target: "app",
      detail: { target: targets, changes: changes },
    };
    return postApi("/tasks/", reqBody);
  }, []);

  return {
    windowsApplications,
    macosApplications,
    ipadApplications,
    isLoading,
    members,
    saveChanges,
    saveChangesLocal,
  };
};

export const useAppstore = () => {
  const [appstoreApplications, setAppstoreApplications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { refresh } = useContext(PortalContext);

  const fetchAppstoreApplications = useCallback(() => {
    setIsLoading(true);
    getApi(`/appstore/`, {}).then((response) => {
      setAppstoreApplications(response.data);
      setIsLoading(false);
    });
  }, [refresh]);

  useEffect(() => {
    fetchAppstoreApplications();
  }, [fetchAppstoreApplications, refresh]);
  return { appstoreApplications, isLoading };
};
