import React, { useState, useEffect, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { postApi } from "../../tools/axiosInstances";
import Tooltip from "../layout/tooltip/Tooltip";
import { useToggleComponent } from "../../tools/hooks";
import { PortalContext } from "../../PortalContext";
import ModalCard, {
  ModalCardTitle,
  ModalCardBody,
  ModalCardButtons,
} from "../layout/ModalCard";
import Field from "../layout/design/Field";
import FieldArea from "../layout/design/FieldArea";
import StandardButton from "../layout/buttons/StandardButton";
import { ReactComponent as StudentDevices } from "../icons/studentDevices.svg";
import { ReactComponent as TeacherDevices } from "../icons/teacherDevices.svg";
import { ReactComponent as SharedDevices } from "../icons/sharedDevices.svg";
import { ReactComponent as AdminDevices } from "../icons/admnistrationDevices.svg";
import { ReactComponent as CategoryIcon } from "../icons/categoryIcon.svg";
import { ReactComponent as RoomIcon } from "../icons/roomIcon.svg";
import { ReactComponent as DeleteIcon } from "../icons/remove.svg";
import IconButton from "../layout/buttons/IconButton";
import Checkbox from "../layout/Checkbox";
function GroupCard({ groupInfo }) {
  const { t } = useTranslation("global");
  const [showModal, setShowModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const { taskOnProgress } = useContext(PortalContext);
  useEffect(() => {
    setLoading(taskOnProgress.some((task) => task.detail?.id === groupInfo.id));
  }, [taskOnProgress, groupInfo]);
  const toggle = () => {
    !groupInfo?.dummy && setShowModal(!showModal);
  };
  const toggleDelete = () => setShowDelete(!showDelete);
  const getLogo = (displayName, type) => {
    if (type === "room") {
      return <RoomIcon />;
    }
    switch (displayName) {
      case "Student Devices":
      case "Shared Student Devices":
        return <StudentDevices />;
      case "Teacher Devices":
      case "Shared Teacher Devices":
        return <TeacherDevices />;
      case "Shared Devices":
        return <SharedDevices />;
      case "Administration":
      case "Shared Administration":
        return <AdminDevices />;
      default:
        return <CategoryIcon />;
    }
  };

  return (
    <>
      <Tooltip
        title={groupInfo.description !== " " && groupInfo.description}
        forceHide={showModal || showDelete}
      >
        <div
          className={
            (loading || groupInfo?.dummy
              ? "animate-pulse cursor-progress "
              : "") +
            `border cursor-pointer rounded-2xl items-center text-center hover:text-forange-400 hover:border-forange-400 hover:font-medium font-normal active:bg-forange-200 `
          }
          onClick={toggle}
        >
          <div className="align-middle text-center items-center">
            {getLogo(groupInfo.displayName, groupInfo.type)}
          </div>
          <div className="flex justify-center m-auto text-center w-full truncate gap-2 pb-3 px-2">
            <div className="text-sm text-fgray-300 ">
              {groupInfo?.dummy
                ? t("pages.group.creating")
                : loading && t("pages.group.working_on")}
            </div>
            <div className="list-none items-center  active:rounded truncate">
              {groupInfo.shared
                ? t(
                    `categories.name.${groupInfo.displayName}`,
                    `${groupInfo.displayName.substring(7)} ${t(
                      "categories.name.shared"
                    )}`
                  )
                : t(
                    `categories.name.${groupInfo.displayName}`,
                    groupInfo.displayName
                  )}
            </div>
          </div>
        </div>
        {showModal && (
          <GroupCardModal
            groupInfo={groupInfo}
            toggle={toggle}
            toggleDelete={toggleDelete}
          />
        )}
        {showDelete && (
          <DeleteGroupModal groupInfo={groupInfo} toggle={toggleDelete} />
        )}
      </Tooltip>
    </>
  );
}

export default GroupCard;

function DeleteGroupModal({ groupInfo, toggle }) {
  const { t } = useTranslation("global");
  const [loadingDelete, setLoadingDelete] = useState(false);
  const wrapper = useRef(null);
  useToggleComponent(wrapper, toggle);
  const handleDeleteGroup = () => {
    setLoadingDelete(true);
    let reqBody = {
      detail: {
        displayName: groupInfo.displayName,
        description: groupInfo.description,
        id: groupInfo.id,
      },
      kind: "del",
    };
    if (groupInfo.type === "category") {
      reqBody.target = "cat";
    } else {
      reqBody.target = "rom";
    }
    postApi("/tasks/", reqBody).then((response) => {
      toggle();
      setLoadingDelete(false);
    });
  };

  return (
    <>
      <ModalCard wrapper={wrapper}>
        <ModalCardTitle>
          {t("modal.delete.title", { name: groupInfo.displayName })}
        </ModalCardTitle>
        <ModalCardBody>
          {t("modal.delete.body_1", { name: groupInfo.displayName })}
        </ModalCardBody>
        <ModalCardButtons>
          <StandardButton onClick={toggle} subtle>
            {t(`pages.settings.modal.add.cancel`)}
          </StandardButton>
          <StandardButton onClick={handleDeleteGroup} loading={loadingDelete}>
            {groupInfo.type === "category"
              ? t("pages.group.modal.delete_category")
              : t("pages.group.modal.delete_room")}
          </StandardButton>
        </ModalCardButtons>
      </ModalCard>
    </>
  );
}
function GroupCardModal({ groupInfo, toggle, toggleDelete }) {
  const { t } = useTranslation("global");
  const [valid, setValid] = useState(true);
  const [loadingSave, setloadingSave] = useState(false);
  const wrapper = useRef(null);
  useToggleComponent(wrapper, toggle);
  const handleSaveChanges = () => {
    setloadingSave(true);
    let reqBody = {
      detail: {
        displayName: groupInfo.displayName,
        newName: document.getElementById("editGroupName").value,
        description: document.getElementById("editGroupDescription").value,
        shared: document.getElementById("shared_category")?.checked || false,
        id: groupInfo.id,
      },
      kind: "rnm",
    };
    if (groupInfo.type === "category") {
      reqBody.target = "cat";
    } else {
      reqBody.target = "rom";
    }
    postApi("/tasks/", reqBody).then((response) => {
      toggle();
      setloadingSave(false);
    });
  };

  const deletable = () => {
    const locked = [
      "Student Devices",
      "Teacher Devices",
      "Shared Devices",
      "Administration",
      "Shared Student Devices",
      "Shared Teacher Devices",
      "Shared Administration",
    ];
    return !locked.includes(groupInfo.displayName);
  };
  return (
    <>
      <ModalCard wrapper={wrapper} wide>
        <ModalCardTitle>
          {groupInfo.type === "category"
            ? t("pages.group.modal.edit_category")
            : t("pages.group.modal.edit_room")}
        </ModalCardTitle>
        <ModalCardBody>
          <GroupNameField
            title={t("pages.group.modal.name")}
            value={
              groupInfo.shared && deletable()
                ? groupInfo.displayName.substring(7)
                : groupInfo.displayName
            }
            disabled={!deletable()}
            id="editGroupName"
            valid={valid}
            setValid={setValid}
          />
          <GroupDescriptionFieldArea
            title={t("pages.group.modal.description")}
            value={groupInfo.description}
            disabled={!deletable()}
            id="editGroupDescription"
          />
          {groupInfo.type == "category" && deletable() && (
            <Checkbox
              id="shared_category"
              title={t("pages.group.modal.category.shared")}
              defaultChecked={groupInfo.shared}
            />
          )}
        </ModalCardBody>
        {deletable() && (
          <ModalCardButtons>
            <IconButton
              destructive
              onClick={() => {
                toggle();
                toggleDelete();
              }}
              Icon={DeleteIcon}
            >
              {groupInfo.type === "category"
                ? t("pages.group.modal.delete_category")
                : t("pages.group.modal.delete_room")}
            </IconButton>
            <StandardButton
              onClick={handleSaveChanges}
              full
              loading={loadingSave}
              disabled={!valid}
            >
              {t("pages.group.modal.save")}
            </StandardButton>
          </ModalCardButtons>
        )}
      </ModalCard>
    </>
  );
}

function GroupNameField({ value, valid, setValid, ...attributes }) {
  const { t } = useTranslation("global");
  const [text, setText] = useState(value);
  const handleOnChange = (e) => {
    const txt = e.target.value;
    if (e.target.value.match('^[a-zA-Z0-9-.&äöüÄÖÜ," ]*$') != null) {
      setText(txt);
      setValid(true);
    } else {
      setText(txt);
      setValid(false);
    }
  };
  return (
    <>
      <Field
        value={text}
        onChange={handleOnChange}
        {...attributes}
        invalidTip={t("pages.group.modal.name_invalid")}
        invalid={!valid}
      />
    </>
  );
}

function GroupDescriptionFieldArea({ value, ...attributes }) {
  const [text, setText] = useState(value);
  return (
    <>
      <FieldArea
        value={text}
        onChange={(e) => setText(e.target.value)}
        {...attributes}
      />
    </>
  );
}
