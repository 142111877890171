import React from "react";
import { ReactComponent as ArrowIcon } from "../../icons/arrow.svg";
import Checkbox from '../Checkbox';
function Chip({ arrow, children, profile,selection,className,...attributes }) {
  return (
    <div
      {...attributes}
      className={`rounded-lg border border-fgray-300 justify-center px-3 gap-1 py-1.5 align-middle hover:bg-forange-300 focus:border-forange-400 focus:border-2 disabled:text-fgray-300 disabled:bg-fwhite flex space-x-1 text-base font-normal ${className}`}
    >
      {selection && <input
        type="checkbox"
        className=" w-5 checked:bg-forange-400 accent-forange-400 rounded focus:outline-none"
        onClick={(e) => e.stopPropagation()}
      />}
      {profile && <div className="w-6 h-6 rounded-full bg-fgray-300" />}
      {children}
      {arrow && <ArrowIcon className="rotate-180" />}
    </div>
  );
}

export default Chip;
