import { useEffect, useRef, useState } from "react";
import { useToggleComponent } from "../../../tools/hooks";
import { ReactComponent as CloseX } from "../../icons/cancelX.svg";
const Drawer = ({ isOpen, onClose, children, enableWrapper = true }) => {
  const [visible, setVisible] = useState(isOpen);
  const wrapper = useRef(null);
  useToggleComponent(wrapper, enableWrapper ? onClose : () => {});
  useEffect(() => {
    if (isOpen) {
      setVisible(true);
    }
  }, [isOpen]);

  const handleAnimationEnd = () => {
    if (!isOpen) {
      setVisible(false); // Unmount after animation ends
    }
  };

  if (!visible) return null;

  return (
    <div
      className={`fixed z-30 top-0 right-0 h-screen w-[480px] bg-white shadow-lg transition-transform duration-500 ease-in-out ${
        isOpen ? "translate-x-0" : "translate-x-full"
      }`}
      onAnimationEnd={handleAnimationEnd}
      ref={wrapper}
    >
      <div className="">{children}</div>
    </div>
  );
};

export function DrawerTitle({ onClose, children }) {
  return (
    <>
      <div className="flex gap-2 m-auto p-6 align-top">
        {onClose && (
          <button
            className=" top-4 left-4 text-gray-600 hover:text-black"
            onClick={onClose}
          >
            <CloseX className="h-6 w-6" />
          </button>
        )}
        <div className="text-xl font-bold w-full">{children}</div>
      </div>
    </>
  );
}

export function DrawerBody({ children, className }) {
  return <div className={`p-2 ${className}`}>{children}</div>;
}
export function DrawerButtons({ children }) {
  return (
    <div className="absolute flex bottom-0 w-full justify-end gap-2 p-6">
      {children}
    </div>
  );
}

export default Drawer;
