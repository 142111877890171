import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

export function TooltipV2({ title, mouse, children, position, forceHide }) {
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  const [tooltipPos, setTooltipPos] = useState(false);
  const [parentPos, setParentPos] = useState(false);
  const [hide, setHide] = useState(false);
  const tooltipRef = useRef(null);
  const parentRef = useRef(null);
  const { t } = useTranslation("global");
  useEffect(() => {
    setHide(forceHide);
  }, [forceHide]);
  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePos({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);
  useEffect(() => {
    if (tooltipRef.current != null) {
      setTooltipPos(tooltipRef.current.getBoundingClientRect());
    }
    if (parentRef.current != null) {
      setParentPos(parentRef.current.getBoundingClientRect());
    }
  }, []);
  if (hide) {
    return <>{children}</>;
  } else {
    // console.log(`${title} - ${position}`)
    switch (position) {
      case "mouse":
        return (
          <>
            
              <div className="has-tooltip" ref={parentRef}>
                {children}
                {title && (
                  <div
                    className="px-2 py-1 rounded line-clamp-4 bg-fgray-400 shadow justify-start tooltip text-fwhite max-w-[400px] text-xs delay-750 transition-opacity "
                    style={{
                      top: `${mousePos.y + 20}px`,
                      left: `${mousePos.x + 20}px`,
                    }}
                    ref={tooltipRef}
                  >
                    {t(title, title)}
                  </div>
                )}
              </div>
          
          </>
        );
        break;
      case "top":
      case "top-start":
        return (
          <>
           
              <div className="has-tooltip" ref={parentRef}>
                <>
                  {title && (
                    <div
                      className="px-2 py-1 rounded line-clamp-4 bg-fgray-400 shadow justify-start mt-1  tooltip text-fwhite max-w-[400px] text-xs transition-opacity "
                      ref={tooltipRef}
                      style={{
                        marginTop: -tooltipPos.height,
                      }}
                    >
                      {t(title, title)}
                    </div>
                  )}
                  {children}
                </>
              </div>
           
          </>
        );
        break;
      case "top-end":
        return (
          <>
           
              <div className="has-tooltip justify-end" ref={parentRef}>
                <>
                  {title && (
                    <div
                      className="px-2 py-1 rounded line-clamp-4 bg-fgray-400 shadow justify-start mt-1  tooltip text-fwhite max-w-[400px] text-xs transition-opacity "
                      ref={tooltipRef}
                      style={{
                        marginTop: -tooltipPos.height,
                        marginLeft:
                          tooltipPos.height <= 40
                            ? 0
                            : -(400 - tooltipPos.width),
                      }}
                    >
                      {t(title, title)}
                    </div>
                  )}{" "}
                  {children}
                </>
              </div>
           
          </>
        );
        break;
      case "bottom-end":
        return (
          <>
            
              <div className="has-tooltip" ref={parentRef}>
                <>
                  {children}
                  {title && (
                    <div
                      className="px-2 py-1 rounded line-clamp-4 bg-fgray-400 shadow  mt-1  tooltip text-fwhite max-w-[400px] absolute text-xs transition-opacity "
                      ref={tooltipRef}
                      style={{
                        marginLeft:
                          tooltipPos.height <= 40
                            ? 0
                            : -(400 - tooltipPos.width),
                      }}
                    >
                      {t(title, title)}
                    </div>
                  )}
                </>
              </div>
           
          </>
        );
        break;

      case "bottom":
      case "bottom-start":
      default:
        return (
          <>
            <div className="has-tooltip">
              <>
                {children}
                {title && (
                  <div
                    className="px-2 py-1 rounded line-clamp-4 bg-fgray-400 shadow justify-start mt-1  tooltip text-fwhite max-w-[400px] text-xs transition-opacity"
                    ref={tooltipRef}
                  >
                    {t(title, title)}
                  </div>
                )}
              </>
            </div>
          </>
        );
        break;
    }
  }
}

export const Tooltip = ({ children, title, position = "bottom",forceHide }) => {
  const { t } = useTranslation("global");
  const [isVisible, setIsVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const tooltipRef = useRef(null);
  const targetRef = useRef(null);

  const calculatePosition = () => {
    if (tooltipRef.current && targetRef.current) {
      const targetRect = targetRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      let newTop = 0;
      let newLeft = 0;

      // Adjust the tooltip position based on the 'position' prop
      if (position === "bottom") {
        newTop = targetRect.bottom + 5;
        newLeft =
          targetRect.left + targetRect.width / 2 - tooltipRect.width / 2;
      } else if (position === "top") {
        newTop = targetRect.top - tooltipRect.height - 5;
        newLeft =
          targetRect.left + targetRect.width / 2 - tooltipRect.width / 2;
      } else if (position === "left") {
        newTop =
          targetRect.top + targetRect.height / 2 - tooltipRect.height / 2;
        newLeft = targetRect.left - tooltipRect.width - 5;
      } else if (position === "right") {
        newTop =
          targetRect.top + targetRect.height / 2 - tooltipRect.height / 2;
        newLeft = targetRect.right + 5;
      }

      // Adjust to prevent overflow from the viewport
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      if (newLeft + tooltipRect.width > viewportWidth) {
        newLeft = viewportWidth - tooltipRect.width - 10; // Small margin from the right edge
      } else if (newLeft < 0) {
        newLeft = 10; // Small margin from the left edge
      }

      if (newTop + tooltipRect.height > viewportHeight) {
        newTop = viewportHeight - tooltipRect.height - 10; // Adjust to prevent overflow at the bottom
      } else if (newTop < 0) {
        newTop = 10; // Adjust to prevent overflow at the top
      }

      setTooltipPosition({ top: newTop, left: newLeft });
    }
  };

  // Update tooltip position on visibility change and add scroll listeners
  useEffect(() => {
    if (isVisible) {
      calculatePosition(); // Initial position calculation
      const handleScroll = () => {
        calculatePosition(); // Update position on scroll
      };

      window.addEventListener("scroll", handleScroll, true); // true for capturing phase
      window.addEventListener("resize", handleScroll); // Recalculate on resize as well

      return () => {
        window.removeEventListener("scroll", handleScroll, true);
        window.removeEventListener("resize", handleScroll);
      };
    }
  }, [isVisible]);

  const handleMouseOver = () => {
    forceHide ? setIsVisible(false) : setIsVisible(true);
  };

  const handleMouseOut = () => {
    forceHide ? setIsVisible(false) : setIsVisible(false);
  };

  // useEffect(() => {
  //   const updateTooltipPosition = () => {
  //     if (isVisible && tooltipRef.current && targetRef.current) {
  //       const targetRect = targetRef.current.getBoundingClientRect();
  //       setTooltipPosition({
  //         top: targetRect.bottom + window.scrollY + 5,
  //         left: targetRect.left + window.scrollX,
  //       });
  //     }
  //   };

  //   window.addEventListener("scroll", updateTooltipPosition);
  //   window.addEventListener("resize", updateTooltipPosition);

  //   return () => {
  //     window.removeEventListener("scroll", updateTooltipPosition);
  //     window.removeEventListener("resize", updateTooltipPosition);
  //   };
  // }, [isVisible]);

  return (
    <div className="relative inline-block" ref={targetRef}>
      {/* Element that triggers the tooltip */}
      <span
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        className="cursor-pointer"
      >
        {children}
      </span>

      {/* Tooltip */}
      {isVisible && title && (
        <div
          ref={tooltipRef}
          className="pointer-events-none fixed z-50 bg-fgray-400 text-fwhite text-xs py-2 px-4 rounded-lg transition-opacity shadow max-w-sm justify-start"
          style={{
            top: `${tooltipPosition.top}px`,
            left: `${tooltipPosition.left}px`,
            maxWidth: "400px",
            whiteSpace: "normal",
          }}
        >
          {t(title, title)}
        </div>
      )}
    </div>
  );
};
export default Tooltip;
