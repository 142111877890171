import React, { useState } from "react";

function DropdownItem({
  id,
  checked,
  checkbox,
  onClick,
  children,
  className,
  ...attributes
}) {
  const [checkeded, setCheckeded] = useState(checked);
  const handleCheckboxOnClick = (e) => {
    onClick && onClick({ id: id, checked: !checkeded });
    setCheckeded(!checkeded);
  };

  return (
    <div
      className={`flex px-5 py-2.5 hover:font-medium font-normal space-x-2 cursor-pointer ${className}`}
      onClick={handleCheckboxOnClick}
    >
      {checkbox && (
        <Checkbox
          id={id}
          checked={checkeded}
          onChange={handleCheckboxOnClick}
        />
      )}
      {children}
    </div>
  );
}

function Checkbox({ ...rest }) {
  return (
    <input
      type="checkbox"
      {...rest}
      className="checked:bg-forange-400 indeterminate:bg-forange-400 rounded focus:outline-none"
    />
  );
}

export default DropdownItem;
