import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import TabBar from "../components/layout/TabBar";
import GroupGrid from "../components/group/GroupGrid";
import NewGroupButton from "../components/group/NewGroupButton";
import LoadingGroupCard from "../components/layout/loading/LoadingGroupCard";
import { useCheckLicenseStatus } from "../tools/utilties";
import PageTitle from "../components/layout/PageTitle";
import PageContent from "../components/layout/PageContent";
import { PortalContext } from "../PortalContext";
import GroupCard from "../components/group/GroupCard";
function Group() {
  const { t } = useTranslation("global");
  const { categories, rooms, makeRefreshGroups, taskOnProgress } = useContext(PortalContext);
  const [tab, setTab] = useState(0);
  const [groupCards, setGroupCards] = useState([]);
  const handleSetFilter = (tabId) => {
    setTab(tabId);
    switch (tabId) {
      case 0:
      default:
        categories && rooms && setGroupCards([...categories, ...rooms]);
        break;
      case 1:
        setGroupCards(categories);
        break;
      case 2:
        setGroupCards(rooms);
        break;
    }
  };
  useEffect(() => makeRefreshGroups(), []);
  useEffect(() => handleSetFilter(tab), [categories, rooms, makeRefreshGroups, tab]);

  useCheckLicenseStatus();
  return (
    <>
      <PageTitle title={t("pages.group.title")}>
        <NewGroupButton />
      </PageTitle>
      <PageContent>
        <TabBar
          options={[
            { title: t("words.all"), id: 0 },
            { title: t("pages.group.category"), id: 1 },
            { title: t("pages.group.room"), id: 2 },
          ]}
          handler={handleSetFilter}
          activeTab={tab}
        />
        <div className="mx-auto mt-5 overflow-y-auto h-[calc(100%-4rem)]">
          {groupCards.length > 0 ? (
            <>
              <div className="grid grid-cols-4 gap-4 mb-12 items-start w-full">
                {groupCards.map((group, index) => (
                  <GroupCard groupInfo={group} key={`${group.id}-${index}`} />
                ))}
                {taskOnProgress.map((task) => {
                  if (
                    (task.target === "room" || task.target === "category") &&
                    task.kind === "create"
                  ) {
                    // if creating a room or category
                    return <GroupCard groupInfo={{...task.detail,dummy:true,type:task.target}} key={`dummy-${task.id}`} />;
                  }
                })}
              </div>
            </>
          ) : (
            <LoadingGroupCard />
          )}
        </div>
      </PageContent>
    </>
  );
}

export default Group;
