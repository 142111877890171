import React, { useState, useEffect } from "react";

export default function ModalCard({ wrapper,wide, children }) {
  return (
    <>
      {/* Overlay */}
      <div
        className={`fixed inset-0 bg-fgray-400 bg-opacity-50 overflow-none h-full z-30 w-full`}
      >
        {/* Modal Card */}
        <div
          className={`relative top-60 mx-auto p-5 border ${wide? "w-[530px]"  : "w-96"} shadow-lg rounded-3xl bg-white z-20 space-y-4`}
          onClick={(e) => e.stopPropagation}
          ref={wrapper}
        >
          {children}
        </div>
      </div>
    </>
  );
}

export function ModalCardTitle({ children }) {
  return (
    <>
      <h1 className="text-lg text-black text-left font-medium ">{children}</h1>
    </>
  );
}

export function ModalCardBody({ children, className }) {
  return (
    <>
      <div className={` text-fblack space-y-4 text-left ${className}`}>{children}</div>
    </>
  );
}

export function ModalCardButtons({ children }) {
  return (
    <>
      <div className="flex justify-end space-x-2 pt-4">{children}</div>
    </>
  );
}
