import { useEffect, useState } from "react";

function OptionsBar({ onSelect, options, selected, full }) {
  const [selectedOption, setSelectedOption] = useState(selected);
  const handleOnSelectOption = (option) => {
    setSelectedOption(option.value);
    onSelect && onSelect(option.value);
  };
  useEffect(() => setSelectedOption(selected), [selected]);
  return (
    <>
      <div
        className={`${
          full ? "w-full" : "w-[52rem]"
        } flex border-b-[1px] text-fblack border-fgray-200 z-20 sticky top-0 bg-white justify-between`}
      >
        <div className="flex">
          {options.map((option, index) => (
            <div
              className={`flex px-4 py-1 cursor-pointer border-b-2 z-10 ${
                selectedOption === option.value
                  ? " border-forange-400 text-forange-400 font-bold"
                  : "border-transparent"
              }`}
              key={index}
              onClick={() => handleOnSelectOption(option)}
            >
              {option?.label || option.value}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default OptionsBar;
