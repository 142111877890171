import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import AppCard from "../components/appstore/AppCard";
import LoadingAppCard from "../components/layout/loading/LoadingAppCard";
import { ValidateRole, useCheckLicenseStatus } from "../tools/utilties";
import { PortalContext } from "../PortalContext";
import { useNavigate } from "react-router-dom";
import TenantChannel from "../components/topbar/TenantChannel";
import PageTitle from "../components/layout/PageTitle";
import PageContent, { PageContentBody } from "../components/layout/PageContent";
import AppstoreSidebar from "../components/application/AppstoreSidebar";
import DeviceModeBar from "../components/layout/DeviceModeBar";
import { useAppstore } from "../hooks/useApplication";
import { ReactComponent as ArrowBackIcon } from "../components/icons/arrowBack.svg";
import { ReactComponent as GhostImage } from "../images/ghost.svg";
function Appstore() {
  const { t } = useTranslation("global");
  const { deviceMode } = useContext(PortalContext);
  const { appstoreApplications, isLoading } = useAppstore();
  const [apps, setApps] = useState([]);
  const [tab, setTab] = useState("all");
  const [filtered, setFiltered] = useState(false);
  const [reloadList, setreloadList] = useState(0);
  const [installModal, setInstallModal] = useState(false);
  const [selected, setSelected] = useState(false);
  const [updateBadge, setUpdateBadge] = useState(999);
  const navigate = useNavigate();
  const toggleInstall = () => {
    setInstallModal(!installModal);
  };
  useCheckLicenseStatus();
  function tabHandler(name) {
    setTab(name);
    name === 0 ? setFiltered(false) : setFiltered(true);
  }
  useEffect(() => {
    if (deviceMode != "windows") {
      navigate("/");
    }
  }, [deviceMode]);

  const reload = () => setreloadList((p) => p + 1);
  useEffect(() => {
    ValidateRole();
  }, []);
  useEffect(() => {
    let totalUpdates = 0;
    !isLoading &&
      appstoreApplications?.map((app) => {
        if (app.updatable) {
          totalUpdates++;
        }
      });
    setUpdateBadge(totalUpdates);
  }, [appstoreApplications]);
  useEffect(() => {
    switch (tab) {
      case "all":
        setApps(appstoreApplications);
        break;
      case "downloaded":
        setApps(appstoreApplications.filter((app) => app.isInstalled));
        break;
      case "updatable":
        setApps(appstoreApplications.filter((app) => app.updatable));
        break;
      default:
        setApps([]);
        break;
    }
  }, [tab, appstoreApplications]);
  return (
    <>
      <PageTitle
        title={t("pages.appstore.title")}
        Icon={ArrowBackIcon}
        iconOnClick={() => navigate(-1)}
      />
      <PageContent sidebar>
        <AppstoreSidebar
          selected={tab}
          onChangeSelected={setTab}
          badge={updateBadge}
        />
        <PageContentBody>
          <DeviceModeBar target="appstore" />
          <TenantChannel />
          <div
            key="appstoreGrid"
            className="grid grid-cols-3 gap-4 mb-12 items-start"
          >
            {!isLoading ? (
              apps.length > 0 ? (
                apps?.map((app, index) => (
                  <AppCard
                    info={app}
                    key={`app${index}`}
                    toggleInstall={toggleInstall}
                    select={setSelected}
                    reload={reload}
                  />
                ))
              ) : (
                <NoApplicationsMessage tab={tab} />
              )
            ) : (
              <LoadingAppCard />
            )}
          </div>
        </PageContentBody>
      </PageContent>
    </>
  );
}

export default Appstore;

const NoApplicationsMessage = ({tab}) => {
  const { t } = useTranslation("global");
  return (
    <div className=" py-8">
      <div className="text-fgray-400 text-xl p-5 space-y-4 text-center place-items-center w-[835px] ">
        <GhostImage />
        <p>{t(`pages.appstore.no_${tab}`)}</p>
      </div>
    </div>
  );
};