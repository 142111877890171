import React, { useState, useMemo, useEffect, useContext } from "react";
import {
  createColumnHelper,
  getSortedRowModel,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import IndeterminateCheckbox from "../layout/IndeterminateCheckbox";
import { rankItem } from "@tanstack/match-sorter-utils";
import ApplicationLogo from "./ApplicationLogo";
import { PortalContext } from "../../PortalContext";
import TableLayout, {
  TableBody,
  TableDataCell,
  TableDataRow,
  TableHeader,
  TableHeaderRow,
} from "../layout/design/Table";
import { ReactComponent as GhostImage } from "../../images/ghost.svg";
import { ReactComponent as Chevron } from "../icons/arrow.svg";
import ApplicationFilterBar from "./ApplicationFilterBar";
import { muxFilter } from "../device/DeviceTableFilterFn";
import LoadingTable from "../layout/loading/LoadingTable";
function ApplicationTable({
  applications,
  onSelect,
  clear,
  onClickRow,
  showFilter,
}) {
  const { t } = useTranslation("global");
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);

  const { deviceMode } = useContext(PortalContext);
  const [rowSelection, setRowSelection] = useState({});
  const [lastSelected, setLastSelected] = useState([]);
  const [isTableReady,setIsTableReady] = useState(false)
  const data = useMemo(
    () =>
      applications.map((app) => ({
        ...app,
        installed: app?.installed.map((itm) => itm.displayName).join(","),
        uninstalled: app?.uninstalled.map((itm) => itm.displayName).join(","),
        appStatus: t(`status.application.${app.appStatus}`),
        appSource: t(`properties.application.${app?.appSource}`, "-"),
      })),
    [applications, deviceMode]
  );
  const columnVisibility = {
    type: false,
    installed: false,
    uninstalled: false,
    appSource: false,
  };
  const columnHelper = createColumnHelper();
  const fuzzyFilter = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value);

    // Store the itemRank info
    addMeta({
      itemRank,
    });

    // Return if the item should be filtered in/out
    return itemRank.passed;
  };
  const columns = [
    columnHelper.accessor("select", {
      header: ({ table }) => (
        <IndeterminateCheckbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      ),
      className: "w-16",
      classNameHeader: "w-16",
      cell: ({ row }) => (
        <IndeterminateCheckbox
          checked={row.getIsSelected()}
          disabled={!row.getCanSelect}
          indeterminate={row.getIsSomeSelected()}
          onChange={(e) => {
            handleSelection(e, row);
          }}
        />
      ),
      enableResizing: false,
      enableSorting: false,
      enableColumnFilter: false,
    }),
    columnHelper.accessor("logo", {
      header: () => "",
      cell: (info) => <ApplicationLogo logoUrl={info.getValue()} />,
      enableSorting: false,
      enableResizing: false,
      className: "w-[200px]",
      classNameHeader: "w-[200px]",
      enableColumnFilter: false,
      enableGlobalFilter: false,
    }),
    columnHelper.accessor("displayName", {
      header: () => t("pages.application.table.application_name"),
      cell: (info) => info.getValue(),
      enableResizing: false,
      className: "text-left cursor-pointer truncate px-2",
      classNameHeader: "cursor-pointer",
      label: t("pages.application.table.application_name"),
      columnFilterType: "text",
      filterFn: muxFilter,
    }),
    columnHelper.accessor("appStatus", {
      header: () => t("properties.app_status"),
      // cell: (info) => t(`status.application.${info.getValue()}`),
      enableResizing: false,
      className:
        "text-left cursor-pointer truncate whitespace-nowrap px-2 w-[250px]",
      classNameHeader: "cursor-pointer",
      label: t("properties.app_status"),
      columnFilterType: "option",
    }),
    columnHelper.accessor("type", {
      enableColumnFilter: false,
    }),
    columnHelper.accessor("installed", {
      enableColumnFilter: true,
      columnFilterType: "objectFilter",
      label: t("status.installed"),
    }),
    columnHelper.accessor("uninstalled", {
      enableColumnFilter: true,
      columnFilterType: "objectFilter",
      label: t("status.uninstalled"),
    }),
    columnHelper.accessor("appSource", {
      enableColumnFilter: true,
      columnFilterType: "objectFilter",
      label: t("properties.app_source"),
    }),
  ];
  const clearColumnFilters = () => {}; //setColumnFilters([]);
  const handleSelection = (e, row) => {
    setLastSelected(row);
    if (e.nativeEvent.shiftKey) {
      const sortedRows = table.getSortedRowModel().rows;
      const lastSelectedIndex = sortedRows.findIndex(
        (r) => r.id == lastSelected.id
      );
      const clickedIndex = sortedRows.findIndex((r) => r.id == row.id);

      if (lastSelectedIndex < clickedIndex) {
        for (let i = lastSelectedIndex; i <= clickedIndex; i++) {
          const element = sortedRows[i];
          !element.getIsSelected() && element.toggleSelected(true);
        }
      } else {
        for (let i = clickedIndex; i <= lastSelectedIndex; i++) {
          const element = sortedRows[i];
          !element.getIsSelected() && element.toggleSelected(true);
        }
      }
    } else {
      row.toggleSelected();
    }
  };
  const handleColumnFilterChange = (e) => {
    setColumnFilters(e);
    table.setRowSelection({});
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      globalFilter,
      rowSelection,
      sorting,
      columnVisibility,
      columnFilters,
    },
    enableColumnResizing: true,
    enableColumnFilters: true,
    columnResizeMode: "onChange",
    enableRowSelection: true,
    enableGlobalFilter: true,
    onRowSelectionChange: setRowSelection,
    onColumnFiltersChange: handleColumnFilterChange,
    onSortingChange: setSorting,
    onGlobalFilterFn: fuzzyFilter,
    globalFilterChange: setGlobalFilter,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });
  useEffect(() => {
    onSelect(table.getSelectedRowModel().flatRows);
  }, [rowSelection]);
  useEffect(() => {
    table.getColumn("type").setFilterValue(deviceMode);
    table.setRowSelection({});
    // clearColumnFilters();
  }, [deviceMode]);
  useEffect(() => {
    table.resetRowSelection(true);
    clearColumnFilters();
  }, [clear]);
  useEffect(() => {
    if (!showFilter) clearColumnFilters();
  }, [showFilter]);
  useEffect(() => {
    setGlobalFilter(deviceMode);
  }, [deviceMode]);
  useEffect(()=>{
    if(applications.length > 0 && table.getRowModel().rows.length > 0) setIsTableReady(true)
  },[applications,table])
  return (
    <>
      <ApplicationFilterBar table={table} show={showFilter} />
      {!isTableReady ? (
        <LoadingTable />
      ) : table.getRowModel().rows.length > 0 ? (
        <TableLayout>
          <TableHeaderRow>
            {table
              .getHeaderGroups()
              .map((headerGroup) =>
                headerGroup.headers.map((header, index) => (
                  <TableHeader header={header} key={`header-${index}`} />
                ))
              )}
            <td className="w-4" />
          </TableHeaderRow>
          <TableBody>
            {table.getRowModel().rows.map((row, index) => (
              <TableDataRow
                isSelected={row.getIsSelected()}
                // onClick={() => onClickRow(row.original)}
                onClick={() =>
                  onClickRow(applications.find((a) => a.id === row.original.id))
                }
                key={`row-${index}`}
              >
                {row.getVisibleCells().map((cell, index) => (
                  <TableDataCell cell={cell} key={`cell-${index}`} />
                ))}
                <td className="w-4">
                  <Chevron className="rotate-90 text-fgray-400" />
                </td>
              </TableDataRow>
            ))}
          </TableBody>
        </TableLayout>
      ) : (
        <NoApplicationsMessage />
      )}
    </>
  );
}
const NoApplicationsMessage = () => {
  const { t } = useTranslation("global");
  return (
    <div className=" py-8 w-full">
      <div className="text-fgray-400 text-xl p-5 space-y-4 text-center place-items-center ">
        <GhostImage />
        <p>{t("pages.application.table.no_applications")}</p>
      </div>
    </div>
  );
};
export default ApplicationTable;
