import { useCallback, useContext, useState, useEffect } from "react";
import { PortalContext } from "../PortalContext";
import { getApi, getApiDelegated } from "../tools/axiosInstances";
import { isEqual } from "lodash";
import { isUUID4 } from "../tools/utilties";

const initialize = (target) => {
  let key = "windowsData";
  switch (target) {
    case "windows":
    default:
      key = "windowsData";
      break;
    case "ipad":
      key = "ipadData";
      break;
    case "macos":
      key = "macosData";
      break;
    case "unenrolled":
      key = "unenrolledData";
      break;
    case "entra":
      key = "entraData";
      break;
  }
  const info = JSON.parse(sessionStorage.getItem(key));
  return info || false;
};

export const useDevice = () => {
  const [windowsData, setWindowsData] = useState(initialize("windows"));
  const [ipadData, setIpadData] = useState(initialize("ipad"));
  const [macosData, setMacosData] = useState(initialize("macos"));
  const [entraData, setEntraData] = useState(initialize("entra")||[]);
  const [unenrolledData, setUnenrolledData] = useState(
    initialize("unenrolled")
  );
  const [loading, setLoading] = useState(false);
  const { ipadOnboarded, macosOnboarded, refresh } = useContext(PortalContext);

  const handleFetchWindows = useCallback(async () => {
    getApi("/device/windows/", {}).then((list) => {
      if (
        !isEqual(
          JSON.stringify(list.data),
          sessionStorage.getItem("windowsData")
        )
      ) {
        setWindowsData(list.data);
        sessionStorage.setItem("windowsData", JSON.stringify(list.data));
        list.data.map((device) => {
          sessionStorage.setItem(device.id, JSON.stringify(device));
        });
      }
      setLoading(false);
    });
  }, []);
  const handleFetchUnenrolled = useCallback(async () => {
    getApi("/device/unenrolled/", {}).then((list) => {
      if (
        !isEqual(
          JSON.stringify(list.data),
          sessionStorage.getItem("unenrolledData")
        )
      ) {
        setUnenrolledData(list.data);
        sessionStorage.setItem("unenrolledData", JSON.stringify(list.data));
        list.data.map((device) => {
          sessionStorage.setItem(device.id, JSON.stringify(device));
        });
      }
    });
  }, []);
  const handleFetchEntra = useCallback(async () => {
    getApi("/device/entra/").then((list) => {
      if (
        !isEqual(JSON.stringify(list.data), sessionStorage.getItem("entraData"))
      ) {
        setEntraData(list.data);
        sessionStorage.setItem("entraData", JSON.stringify(list.data));
      }
    });
  }, []);

  const handleFetchIpad = useCallback(async () => {
    ipadOnboarded &&
      getApi("/device/ipad/", {}).then((list) => {
        if (
          !isEqual(
            JSON.stringify(list.data),
            sessionStorage.getItem("ipadData")
          )
        ) {
          setIpadData(list.data);
          sessionStorage.setItem("ipadData", JSON.stringify(list.data));
          list.data.map((device) => {
            sessionStorage.setItem(device.id, JSON.stringify(device));
          });
        }
        setLoading(false);
      });
  }, [ipadOnboarded]);
  const handleFetchMacos = useCallback(async () => {
    macosOnboarded &&
      getApi("/device/macos/", {}).then((list) => {
        if (
          !isEqual(
            JSON.stringify(list.data),
            sessionStorage.getItem("macosData")
          )
        ) {
          setMacosData(list.data);
          sessionStorage.setItem("macosData", JSON.stringify(list.data));
          list.data.map((device) => {
            sessionStorage.setItem(device.id, JSON.stringify(device));
          });
        }
        setLoading(false);
      });
  }, [macosOnboarded]);
  useEffect(() => {
    handleFetchWindows();
    handleFetchIpad();
    handleFetchMacos();
    handleFetchUnenrolled();
    handleFetchEntra();
  }, [
    handleFetchIpad,
    handleFetchMacos,
    handleFetchUnenrolled,
    handleFetchWindows,
    handleFetchEntra,
    refresh,
  ]);

  const refreshData = useCallback(() => {
    setLoading(true);
    handleFetchWindows();
    handleFetchIpad();
    handleFetchMacos();
    handleFetchEntra();
  }, [handleFetchEntra, handleFetchIpad, handleFetchMacos, handleFetchWindows]);

  return {
    windowsData,
    ipadData,
    macosData,
    unenrolledData,
    entraData,
    isLoading: loading,
    update: refreshData,
  };
};

export const useDeviceDetails = (deviceId) => {
  const { setDeviceMode } = useContext(PortalContext);
  const [deviceInfo, setDeviceInfo] = useState(false);
  const [deviceStatus, setDeviceStatus] = useState(null);
  const [deviceBitlocker, setDeviceBitlocker] = useState("loading");
  const [deviceApps, setDeviceApps] = useState(false);
  const enrolled = isUUID4(deviceId);
  const handleFetchDeviceInfo = useCallback(async () => {
    if (enrolled) {
      getApi(`/device/${deviceId}/`, {}).then((info) => {
        setDeviceInfo(info.data);
        setDeviceMode(info.data.type.toLowerCase());
        setDeviceStatus(info.data.statusInfo);
      });
      getApi(`/device/${deviceId}/apps`, {}).then((apps) => {
        setDeviceApps(apps.data);
      });
    } else {
      getApi(`/device/unenrolled/${deviceId}/`, {}).then((info) => {
        setDeviceInfo(info.data);
        setDeviceMode(info.data.type.toLowerCase());
        setDeviceStatus(info.data.statusInfo);
      });
    }
  }, [deviceId, enrolled, setDeviceMode]);
  const handleFetchDeviceExtraInfo = useCallback(async () => {
    if (deviceInfo.type?.toLowerCase() === "windows" && enrolled) {
      getApiDelegated(`/device/${deviceId}/bitlocker/`).then((keys) => {
        keys.data.length > 0
          ? setDeviceBitlocker(keys.data)
          : setDeviceBitlocker(false);
      });
    } else if (deviceInfo.type?.toLowerCase() === "macos" && enrolled) {
      getApi(`/device/${deviceId}/filevault/`).then((keys) => {
        keys.data.length > 0
          ? setDeviceBitlocker(keys.data)
          : setDeviceBitlocker(false);
      });
    }
  }, [deviceId, deviceInfo.type, enrolled]);
  useEffect(() => {
    handleFetchDeviceInfo();
  }, [handleFetchDeviceInfo]);
  useEffect(() => {
    handleFetchDeviceExtraInfo();
  }, [deviceInfo, handleFetchDeviceExtraInfo]);

  return {
    deviceInfo,
    deviceStatus,
    deviceBitlocker,
    deviceApps,
    deviceEnrolled: enrolled,
  };
};
