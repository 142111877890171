import { useCallback, useContext, useState } from "react";
import {
  ApplicationContext,
  ApplicationContextProvider,
} from "../components/application/applicationContext";
import PageTitle from "../components/layout/PageTitle";
import { useTranslation } from "react-i18next";
import { useApplication } from "../hooks/useApplication";
import PageContent, { PageContentBody } from "../components/layout/PageContent";
import RefreshButton from "../components/layout/buttons/RefreshButton";
import { ReactComponent as UninstallLogo } from "../components/icons/uninstall.svg";
import { ReactComponent as InstallLogo } from "../components/icons/install.svg";
import { ReactComponent as RemoveLogo } from "../components/icons/remove.svg";
import { ReactComponent as AppstoreLogo } from "../components/icons/appstoreLogo.svg";
import ApplicationActionModalButton from "../components/application/ApplicationActionModalButton";
import { useOnboarded } from "../hooks/useOnboarded";
import LoadingTable from "../components/layout/loading/LoadingTable";
import Onboarding from "../components/device/Onboarding";
import ApplicationTable from "../components/application/ApplicationTable";
import DeviceModeBar from "../components/layout/DeviceModeBar";
import ApplicationDrawer from "../components/application/ApplicationDrawer";
import ModalCard, {
  ModalCardBody,
  ModalCardButtons,
  ModalCardTitle,
} from "../components/layout/ModalCard";
import BaseButton from "../components/layout/buttons/BaseButton";
import IconButton from "../components/layout/buttons/IconButton";
import { useNavigate } from "react-router-dom";
import { PortalContext } from "../PortalContext";
function ApplicationPage() {
  const { t } = useTranslation("global");
  const {
    windowsApplications,
    ipadApplications,
    macosApplications,
    isLoading,
    saveChanges,
    saveChangesLocal,
  } = useApplication();
  const { onboarded } = useOnboarded();
  const [selected, setSelected] = useState([]);
  const [clear, setClear] = useState(0);
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerInfo, setDrawerInfo] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [showModalChanges, setShowModalChanges] = useState(false);
  const { pendingChanges, setPendingChanges } = useContext(ApplicationContext);
  const { deviceMode } = useContext(PortalContext);
  const navigate = useNavigate();
  const showSidePanel = useCallback(() => {
    setShowDrawer(true);
    clearSelected();
  }, []);
  const hideSidePanel = useCallback(() => {
    if (pendingChanges) {
      showWarningUnsaved();
    } else {
      setShowDrawer(false);
      setPendingChanges(false);
    }
  }, [pendingChanges, setPendingChanges]);
  const allApps = useCallback(() => {
    let all = [...windowsApplications];
    if (ipadApplications) {
      all = [...all,...ipadApplications];
    }
    if (macosApplications) {
      all = [...all,...macosApplications];
    }
    return all;
  }, [windowsApplications, ipadApplications, macosApplications]);
  const showWarningUnsaved = () => setShowModalChanges(true);
  const hideWarningUnsaved = () => setShowModalChanges(false);
  const clearSelected = useCallback(() => {
    setSelected([]);
    setClear((p) => p + 1);
  }, []);
  const handleOnSelect = useCallback(
    (rows) =>
      setSelected(
        rows.map((row) => allApps().find((app) => app.id === row.original.id))
      ),
    [allApps]
  );
  const handleOnClickRow = useCallback(
    (application) => {
      if (pendingChanges) {
        showWarningUnsaved();
      } else {
        setDrawerInfo(application);
        showSidePanel();
        setPendingChanges(false);
      }
    },
    [pendingChanges, setPendingChanges]
  );
  const handleCloseWithoutSaving = useCallback(() => {
    hideWarningUnsaved();
    setPendingChanges(false);
    setShowDrawer(false);
  }, [setPendingChanges]);
  const handleDontClose = () => {
    hideWarningUnsaved();
  };
  const handleOnSave = useCallback(
    (prevInfo, newInfo, changes) => {
      saveChanges([prevInfo], changes);
      saveChangesLocal([newInfo]);
      setShowDrawer(false);
      setPendingChanges(false);
    },
    [saveChanges, setPendingChanges]
  );
  const getApplications = useCallback(() => {
    switch (deviceMode) {
      case "windows":
      default:
        return windowsApplications;
      case "ipads":
        return ipadApplications;
      case "macos":
        return macosApplications;
    }
  }, [deviceMode, windowsApplications, ipadApplications, macosApplications]);
  return (
    <>
      <PageTitle title={t("pages.application.title")}>
        <RefreshButton loading={isLoading} />
        <ApplicationActionModalButton
          action="delete"
          Icon={RemoveLogo}
          selected={selected}
          clear={clearSelected}
          onSave={saveChanges}
          // filter={filter}
        />
        <ApplicationActionModalButton
          action="uninstall"
          Icon={UninstallLogo}
          selected={selected}
          clear={clearSelected}
          onSave={saveChanges}
        />
        <ApplicationActionModalButton
          action="install"
          Icon={InstallLogo}
          selected={selected}
          clear={clearSelected}
          onSave={saveChanges}
          // filter={filter}
        />
        {deviceMode === "windows" && (
          <IconButton
            Icon={AppstoreLogo}
            onClick={() => navigate("/appstore/")}
            subtle
          >
            {t("pages.application.add")}
          </IconButton>
        )}
      </PageTitle>
      <PageContent>
        <PageContentBody>
          <DeviceModeBar target="application" full setFilter={setShowFilter} />
          {onboarded() === true ? (
            isLoading ? (
              <LoadingTable />
            ) : (
              <>
                {deviceMode === "windows" ? (
                  windowsApplications ? (
                    <ApplicationTable
                      applications={windowsApplications}
                      onSelect={handleOnSelect}
                      onClickRow={handleOnClickRow}
                      clear={clear}
                      showFilter={showFilter}
                      isLoading={isLoading}
                    />
                  ) : (
                    <LoadingTable />
                  )
                ) : deviceMode === "ipad" ? (
                  ipadApplications ? (
                    <ApplicationTable
                      applications={ipadApplications}
                      onSelect={handleOnSelect}
                      onClickRow={handleOnClickRow}
                      clear={clear}
                      showFilter={showFilter}
                      isLoading={isLoading}
                    />
                  ) : (
                    <LoadingTable />
                  )
                ) : deviceMode === "macos" ? (
                  macosApplications ? (
                    <ApplicationTable
                      applications={macosApplications}
                      onSelect={handleOnSelect}
                      onClickRow={handleOnClickRow}
                      clear={clear}
                      showFilter={showFilter}
                      isLoading={isLoading}
                    />
                  ) : (
                    <LoadingTable />
                  )
                ) : (
                  <LoadingTable />
                )}
                <ApplicationDrawer
                  app={drawerInfo}
                  isOpen={showDrawer}
                  onClose={hideSidePanel}
                  onSave={handleOnSave}
                />
                {showModalChanges && (
                  <ModalCard wide>
                    <ModalCardTitle>
                      {t("application.drawer.unsavedChanges.title")}
                    </ModalCardTitle>
                    <ModalCardBody>
                      {t("application.drawer.unsavedChanges.body")}
                    </ModalCardBody>
                    <ModalCardButtons>
                      <BaseButton onClick={handleDontClose} subtle>
                        {t("words.back")}
                      </BaseButton>
                      <BaseButton onClick={handleCloseWithoutSaving}>
                        {t("words.continue")}
                      </BaseButton>
                    </ModalCardButtons>
                  </ModalCard>
                )}
              </>
            )
          ) : (
            <Onboarding target="application" />
          )}
        </PageContentBody>
      </PageContent>
    </>
  );
}

function Application() {
  return (
    <ApplicationContextProvider>
      <ApplicationPage />
    </ApplicationContextProvider>
  );
}

export default Application;
