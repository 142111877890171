import { createContext, useState } from "react";
import dummyData from "./sidePanel/_temp_members.json";
import { useApplication } from "../../hooks/useApplication";

export const ApplicationContext = createContext();

export const ApplicationContextProvider = ({ children }) => {
  const [pendingChanges, setPendingChanges] = useState(false);
  const { members } = useApplication();
  return (
    <ApplicationContext.Provider
      value={{
        members,
        pendingChanges,
        setPendingChanges,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

export const ApplicationContextProviderOffline = ({ children }) => {
  const members = dummyData;
  return (
    <ApplicationContext.Provider value={{ members }}>
      {children}
    </ApplicationContext.Provider>
  );
};
