import React from "react";

function LoadingAppCard() {
  return (
    <div className='animate-pulse flex'>
      <div className='border rounded align-middle object-center text-center justify-items-center w-64 m-2 p-5'>
        <div className='justify-center items-center  mb-5 h-24 '>
          <div className='justify-center align-middle mb-2 flex items-center '>
            <div className='h-16 bg-fgray-200 rounded-full w-16' />
          </div>

          <div className=' h-12  text-base align-text-bottom'>
            <div className='h-5 w-48 rounded content-center bg-fgray-200' />
          </div>
        </div>

        <div className='text-sm min-h-[96px]  '>
          <div className='h-5 w-48 my-2 rounded content-center bg-fgray-200' />
          <div className='h-5 w-48 my-2 rounded content-center bg-fgray-200' />
          <div className='h-5 w-48 my-2 rounded content-center bg-fgray-200' />
        </div>
        <div className='px-2/5 py-2'>
          <div className='h-12 w-48 rounded content-center bg-fgray-200' />
        </div>
      </div>
      <div className='border rounded align-middle object-center text-center justify-items-center w-64 m-2 p-5'>
        <div className='justify-center items-center  mb-5 h-24 '>
          <div className='justify-center align-middle mb-2 flex items-center '>
            <div className='h-16 bg-fgray-200 rounded-full w-16' />
          </div>

          <div className=' h-12  text-base align-text-bottom'>
            <div className='h-5 w-48 rounded content-center bg-fgray-200' />
          </div>
        </div>

        <div className='text-sm min-h-[96px]  '>
          <div className='h-5 w-48 my-2 rounded content-center bg-fgray-200' />
          <div className='h-5 w-48 my-2 rounded content-center bg-fgray-200' />
          <div className='h-5 w-48 my-2 rounded content-center bg-fgray-200' />
        </div>
        <div className='px-2/5 py-2'>
          <div className='h-12 w-48 rounded content-center bg-fgray-200' />
        </div>
      </div>
      <div className='border rounded align-middle object-center text-center justify-items-center w-64 m-2 p-5'>
        <div className='justify-center items-center  mb-5 h-24 '>
          <div className='justify-center align-middle mb-2 flex items-center '>
            <div className='h-16 bg-fgray-200 rounded-full w-16' />
          </div>

          <div className=' h-12  text-base align-text-bottom'>
            <div className='h-5 w-48 rounded content-center bg-fgray-200' />
          </div>
        </div>

        <div className='text-sm min-h-[96px]  '>
          <div className='h-5 w-48 my-2 rounded content-center bg-fgray-200' />
          <div className='h-5 w-48 my-2 rounded content-center bg-fgray-200' />
          <div className='h-5 w-48 my-2 rounded content-center bg-fgray-200' />
        </div>
        <div className='px-2/5 py-2'>
          <div className='h-12 w-48 rounded content-center bg-fgray-200' />
        </div>
      </div>
      {/* <div className='border rounded align-middle object-center text-center justify-items-center w-64 m-2 p-5'>
        <div className='justify-center items-center  mb-5 h-24 '>
          <div className='justify-center align-middle mb-2 flex items-center '>
            <div className='h-16 bg-fgray-200 rounded-full w-16' />
          </div>

          <div className=' h-12  text-base align-text-bottom'>
            <div className='h-5 w-48 rounded content-center bg-fgray-200' />
          </div>
        </div>

        <div className='text-sm min-h-[96px]  '>
          <div className='h-5 w-48 my-2 rounded content-center bg-fgray-200' />
          <div className='h-5 w-48 my-2 rounded content-center bg-fgray-200' />
          <div className='h-5 w-48 my-2 rounded content-center bg-fgray-200' />
        </div>
        <div className='px-2/5 py-2'>
          <div className='h-12 w-48 rounded content-center bg-fgray-200' />
        </div>
      </div> */}
    </div>
  );
}

export default LoadingAppCard;
